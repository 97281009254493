import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  IconChevronRightRegular,
  Inline,
  skinVars,
  Stack,
  Text1,
  Text2,
  Text4,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../../App.styles';
import { useEvents } from '../../../hooks/webapp-events/useEvents';
import { formatDate } from '../../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../util/functions';
import { LoadingRecargaPremiada } from '../../loading/loading';
import { DrawToList } from '../useLogic';

import { CardDrawDetails } from '.';

type SemanalComponentProps = {
  draws: DrawToList[];
};

export const SemanalComponent = ({ draws }: SemanalComponentProps) => {
  const navigateTo = useNavigate();
  const { sendEvents } = useEvents();

  useEffect(() => {
    sendEvents({
      eventType: 'DRAW_PAGE_ACCESS',
      triggerType: 'access',
      subSource: 'mensal',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  if (!draws) {
    return <LoadingRecargaPremiada visible />;
  }

  const renderPrizeDetails = (draw: DrawToList) => (
    <>
      <Box className="left-text" key={Math.random()}>
        <CardDrawDetails drawDetails={draw}></CardDrawDetails>
      </Box>
      <div
        onClick={() => {
          sendEvents({
            eventType: 'BUTTON_CLICK',
            triggerType: 'CLICK',
            subSource: 'mensal',
            subSubSource: 'Meus números da sorte',
            platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
          });
          navigateTo(`/tickets#${draw.drawName}`);
        }}
      >
        <FlexBoxLeft
          className="left-text justify-content-between"
          style={{ justifyContent: 'center' }}
          css={{
            margin: '16px 0px 32px',
          }}
        >
          <Inline space={12} alignItems="center">
            <img
              className="mr-3"
              src="/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-bg-purple.png"
            />
            <>
              <Text2 medium color={skinVars.colors.textPrimary}>
                {draw.accumulatedTickets} Número{draw.accumulatedTickets > 1 ? 's' : ''} da sorte
              </Text2>
              <br />
              <Text1 regular color={skinVars.colors.textSecondary}>
                Confira seus números
              </Text1>
            </>
          </Inline>
          <Box>
            <IconChevronRightRegular />
          </Box>
        </FlexBoxLeft>
      </div>
    </>
  );

  const filterDraws = (draw: DrawToList) =>
    draw.drawGroup === 3; /*&& draw.status === 'IN_PROGRESS'*/

  const renderPrizes = () => (
    <div className="left-text">
      <div className="row">
        {draws.filter(filterDraws).map((draw, i) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={i}>
            {renderPrizeDetails(draw)}
          </div>
        ))}
      </div>
      <Text1 regular color={skinVars.colors.textSecondary}>
        *Imagem de prêmios meramente ilustrativa
      </Text1>
    </div>
  );

  const renderDrawsDates = () => {
    let prizeComponentKey = 0;
    return (
      <Box className="left-text">
        <Text4 light>Data dos sorteios</Text4>
        <br />
        <Box className="row" paddingTop={16}>
          {draws
            .filter((draw: DrawToList) => draw.drawGroup === 3)
            .sort((a: DrawToList, b: DrawToList) => a.drawDate - b.drawDate)
            .map((draw: DrawToList) => {
              prizeComponentKey++;
              return (
                <div className="col-md-6" key={prizeComponentKey}>
                  <FlexBoxLeft
                    className="left-text border-item"
                    // css={{
                    //   borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                    //   padding: '12px 0px',
                    // }}
                  >
                    <Stack space={0}>
                      <Text2 medium transform="capitalize" color={skinVars.colors.textPrimary}>
                        {draw.drawName}
                      </Text2>
                      <Text1 regular color={skinVars.colors.textSecondary}>
                        Período de participação:&nbsp;
                        {formatDate(draw.drawStartDate, 'day-month-year-numeric')}
                        &nbsp;a&nbsp;
                        {formatDate(draw.drawEndDate, 'day-month-year-numeric')}
                      </Text1>
                      <Text1 regular color={skinVars.colors.textSecondary}>
                        Data do sorteio:&nbsp;
                        {formatDate(draw.drawDate, 'day-month-year-numeric')}
                      </Text1>
                    </Stack>
                  </FlexBoxLeft>
                </div>
              );
            })}
        </Box>
      </Box>
    );
  };

  return (
    <Stack space={0}>
      <Box paddingBottom={32} className="left-text">
        <div style={{ marginBottom: '16px' }}>
          <Text4 light color={skinVars.colors.textPrimary}>
            Confira os prêmios que você pode ganhar a cada semana
          </Text4>
        </div>
        <Text2 regular>
          Ao recarregar R$ 20 ou mais, você ganha seu número da sorte para os sorteios semanais
        </Text2>
      </Box>
      <Stack space={32} className="sorteios-mensais">
        {renderPrizes()}
        {renderDrawsDates()}
      </Stack>
    </Stack>
  );
};
