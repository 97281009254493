export const useFaqsList = () => {
  const faqs = [
    {
      question: '1.	O que é a Recarga Premiada?',
      answer:
        'Recarga Premiada é uma campanha promocional exclusiva para clientes Vivo Pré-Pago, em que os clientes ganham recompensas ao realizarem recargas elegíveis. Com recargas de R$ 20 ou mais você garante uma jogada no Estoura Bolha e ganha prêmios instantâneos. Adicionalmente, ao acumular recargas de R$ 30 ou mais, você também recebe um número da sorte para concorrer ao sorteio especial de R$100MIL.',
    },
    {
      question: '2.	Qual a duração da promoção?',
      answer:
        'A promoção Recarga Premiada começa em 01 de novembro de 2023 e se encerra em 30 de novembro de 2023.',
    },
    {
      question: '3.	Quem pode participar da promoção?',
      answer: 'Poderão participar da promoção todos os clientes pré-pagos da Vivo.',
    },
    {
      question: '4.	Quais são os prêmios da promoção?',
      answer:
        'Você pode ganhar prêmios instantâneos como pacotes de dados móveis, franquia de dados para redes sociais, vídeo e/ou música; no sorteio semanal você pode ganhar smartphone A04, fone de ouvido, Smartwatch e Galaxy tab A7 e  R$ 100 mil reais no sorteio especial.  Para conferir a lista completa de prêmios acesse o Regulamento no menu do hotsite ou através do app Vivo.',
    },
    {
      question: '5.	Como ganhar prêmios instantâneos?',
      answer:
        'Basta fazer uma recarga de R$ 20 ou mais, entrar no hotsite da promoção ou no app Vivo e estourar uma bolha premiada. Você descobre na hora qual o é o seu prêmio. Você receberá um sms com o link para acessar o Estoura Bolha, mas você também pode acessar a qualquer momento o site www.vivorecargapremiada.com.br através do seu navegador de internet ou através do App Vivo',
    },
    {
      question: '6.	Quantas jogadas no Estoura Bolhas eu ganho pela minha recarga?',
      answer: 'Por cada recarga de R$ 20 reais ou mais, você ganha 1 jogada no Estoura a Bolha.',
    },
    {
      question: '7.	Como faço para estourar a bolha?',
      answer:
        'Você verá 4 bolhas na tela, é só escolher uma e clicar sobre ela para descobrir o seu prêmio.',
    },
    {
      question: '8.	Eu pago para estourar as bolhas?',
      answer: 'Não! A participação no Estoura Bolha é gratuita.',
    },
    {
      question: '9.	O que acontece com as jogadas que não usei no Estoura Bolhas?',
      answer:
        'Todas as jogadas no Estoura Bolha são válidas até 15/12/2023. Mas, quanto antes você estourar, mais rápido receberá o seu prêmio instantâneo.',
    },
    {
      question: '10.	Fiz uma recarga de R$20 ou mais e não recebi jogadas. ',
      answer:
        'Fique tranquilo! Caso você não veja sua jogada disponível, ela serão disponibilizadas até 72h após o horário da recarga.',
    },
    {
      question:
        '11.	Recebi SMS da Vivo sobre a promoção Recarga Premiada. Serei cobrado por esses SMS?',
      answer:
        'A promoção Recarga Premiada é gratuita. Todos os SMS recebidos com informações sobre a promoção são livres de qualquer cobrança na sua conta.',
    },
    {
      question: '12.	Como participar dos sorteios semanais?',
      answer:
        'Para ganhar números da sorte e concorrer aos sorteios semanal, você deve fazer recarga de R$ 20 ou mais durante o período de participação do sorteio. Os números da sorte recebidos serão válidos apenas para a participação no sorteio semanal correspondente ao período de participação semana',
    },
    {
      question: '13.	Como ganhar um número da sorte para o sorteio especial?',
      answer:
        'Para ganhar um número da sorte adicional para concorrer ao sorteio especial do período vigente, basta acessar o hotsite da promoção através do App VIVO, após acumular o valor de R$ 30,00 (trinta) ou mais em recargas.',
    },
    {
      question: '14.	Como participar do sorteio especial de R$ 100 mil?',
      answer:
        'Ao acumular o valor de R$ 30 em recarga no período vigente você 1 número da sorte e pode aumentar seu números da sorte acumulando valores em recarga conforme tabela abaixo:',
      extraTable: {
        headers: [
          'RECARGA ACUMULADA ao longo de todo o período de participação:',
          'QTDE atribuída de Números da Sorte - Sorteio Especial',
          'QTDE Total Números da Sorte - Sorteio Especial',
        ],
        data: [
          ['R$ 30,00', '1', '1'],
          ['R$ 40,00', '+ 1', '= 2'],
          ['R$ 50,00', '+ 2', '= 4'],
          ['R$ 60,00', '+ 4', '= 8'],
          ['R$ 100,00', '+ 8', '= 16'],
          ['R$ 200,00', '+ 16', '= 32'],
          ['R$ 300,00 ou +', '+ 32', '= 64'],
        ],
      },
    },
    {
      question: '15.	Onde consulto os meus números da sorte para os sorteios?',
      answer:
        'Você pode conferir seus números da sorte, acessando o menu do hotsite e clicando na opção “Meus Números da Sorte”. ',
    },
    {
      question: '16.	Quais são as datas dos sorteios?',
      answer:
        'Você pode consultar as datas dos sorteios e os períodos de participação, acessando a sessão “Sorteios” na página inicial do hotsite ou através do app Vivo.',
    },
    {
      question: '17.	Onde confiro os ganhadores dos sorteios?',
      answer: 'É só acessar o menu do hotsite e clicar em “Ganhadores”. ',
    },
    {
      question: '18.	Fui contemplado. O que devo fazer? ',
      answer:
        'Todos sorteados serão divulgados no hotsite da promoção www.vivorecargapremiada.com.br ou através do app Vivo. Os contemplados também serão contatados pelo número do telefone com o qual participou da promoção para instruções de recebimento do prêmio.',
    },
    {
      question: '19.	O valor do prêmio é livre de tributos?',
      answer:
        'O valor de premiação é líquido de impostos, o prêmio será entregue livre e desembaraçado de qualquer ônus para o contemplado.',
    },
    {
      question: '20.	O cliente contemplado poderá passar o prêmio para o nome de outra pessoa?',
      answer:
        'Não. A entrega do prêmio somente poderá ser realizada para o contemplado após comprovada a sua titularidade. ',
    },
    {
      question: '21.	Ainda estou com dúvidas, o que devo fazer?',
      answer:
        'Em caso de dúvidas em relação a esta promoção que não foram esclarecidas nesse FAQ ou no Regulamento, consulte os canais de atendimento da operadora (*8486)',
    },
    {
      question: '22. Existe algum custo para acessar o hotsite da campanha? ',
      answer: 'Não, o acesso ao hotsite é gratuito, mas o cliente precisa ter acesso a internet.',
    },
    {
      question: '23. Sou cliente Controle, Pós-Pago ou Fibra, posso participar?',
      answer: 'Não. A campanha é exclusiva para clientes pré-pagos da Vivo.',
    },
    {
      question: '24. Posso participar do programa com mais de um número de celular?',
      answer:
        'Sim. Para fins de participação nesta promoção, um cliente é determinado pela posse de ao menos uma linha de telefonia celular registrada sob seu CPF, ou seja, se a mesma pessoa possuir mais de uma linha de telefonia celular, registrada sob seu CPF, esta pessoa poderá participar da promoção através de cada uma dessas linhas, de forma individual e única, não podendo ainda se valer da soma das recargas de linhas de telefonia diversas, mesmo que registradas sob um mesmo CPF, como se fosse uma mesma participação.',
    },
    {
      question:
        '25. O cliente participante pode transferir números da sorte para outra pessoa ou linha?',
      answer: 'Não. O número da sorte e estrelas é intransferível',
    },
    {
      question:
        '26. Participei da Promoção Recarga Premiada entre 01/06/2023 a 31/10/2023, ganhei bolhas e não estourei, ainda posso estourar minhas bolhas?',
      answer:
        'Não, para quem fez recargas de R$20 ou mais no período de 01/06/2023 a 31/10/2023 as bolhas expiraram em 31/10/2023 as 23:59',
    },
  ];

  return {
    faqs,
  };
};
