import { useState } from 'react';
import { Box, ResponsiveLayout, Stack, Tabs } from '@telefonica/mistica';

import { DesktopHomeBanner } from '../home/components/home.styles';

import { PremiosDisponiveisComponent } from './components/premios-disponiveis/voce-pode-ganhar';
import { PremiosGanhosComponent } from './components/premios-ganhos/voce-ja-ganhou';
import { TabsContainer } from './styles';

const Premios = () => {
  const fragment: string = window.location.hash.toLocaleLowerCase().replace('#', '');
  const initialTabState = fragment === 'meus-premios' ? 0 : 1;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(initialTabState);
  const availableTabs = [{ text: 'Você já ganhou' }, { text: 'Você pode ganhar' }];
  // const { isDesktop } = {
  //   isDesktop: useWindowWidth() > 992,
  // };

  return (
    <>
      <DesktopHomeBanner></DesktopHomeBanner>
      <ResponsiveLayout>
        <Box paddingY={8}>
          <Stack space={8} className="tabs-premios">
            <TabsContainer>
              <Tabs
                selectedIndex={selectedTabIndex}
                tabs={availableTabs}
                onChange={setSelectedTabIndex}
              ></Tabs>
            </TabsContainer>
            <Box paddingY={16} className="tab-content">
              {selectedTabIndex === 0 && <PremiosGanhosComponent />}
              {selectedTabIndex === 1 && (
                <PremiosDisponiveisComponent></PremiosDisponiveisComponent>
              )}
            </Box>
          </Stack>
        </Box>
      </ResponsiveLayout>
    </>
  );
};

export default Premios;
