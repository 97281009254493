import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { JWTDecoded } from './types/jwtDecoded';

export const getUUID = () => uuidv4();

export enum COOKIES_KEYS {
  'ACCESS_TOKEN' = 'access_token',
  'IS_FIRST_LOGIN' = 'isFirstLogin',
  'COOKIES_POLICY_DISPLAYED' = 'cookiesPolicyDisplayed',
  'IS_WEBVIEW' = 'isWebview',
  'UTM_SOURCE' = 'utm_source',
  'UTM_CAMPAIGN' = 'utm_campaign',
  'UTM_TERM' = 'utm_term',
  'UTM_CONTENT' = 'utm_content',
  'UTM_MEDIUM' = 'utm_medium',
  'PLATFORM_TYPE' = 'platformType',
}

export const getCookie = (name: COOKIES_KEYS): string => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match?.[2] ?? '';
};

export const decodeAccessToken = (jwt = ''): JWTDecoded => {
  const access_token = !jwt ? getCookie(COOKIES_KEYS.ACCESS_TOKEN) : jwt;
  if (!access_token) {
    return {
      aud: '',
      exp: 0,
      iat: 0,
      identifierType: '',
      iss: '',
      msisdn: '',
      serviceId: '',
    };
  }
  return jwt_decode(access_token) as JWTDecoded;
};

export const generateSessionId = () => {
  const userSession = sessionStorage.getItem('u') ? JSON.parse(sessionStorage.getItem('u')!) : {};

  if (userSession && userSession.s) {
    return userSession.s;
  } else {
    userSession.s = getUUID();
    window.sessionStorage.setItem('u', JSON.stringify(userSession));
    return userSession.s;
  }
};
export const generateDeviceId = () => {
  const userDevice = localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')!) : {};

  if (userDevice && userDevice.d) {
    return userDevice.d;
  } else {
    userDevice.d = getUUID();
    // debugger;
    // userDevice.d = DeviceInfo.getUniqueId();
    window.localStorage.setItem('u', JSON.stringify(userDevice));
    return userDevice.d;
  }
};

export const setCookie = (key: COOKIES_KEYS, value: string) => {
  if (!Boolean(value)) return;
  const currentTime = new Date();
  const expiryDuration =
    key === COOKIES_KEYS.ACCESS_TOKEN ? decodeAccessToken(value).exp : 1000 * 60 * 60; // 1 hour
  currentTime.setTime(currentTime.getTime() + expiryDuration);

  const cookieSettings = {
    [key]: value,
    expires: currentTime.toUTCString(),
    sameSite: 'strict',
  };

  document.cookie = Object.entries(cookieSettings)
    .map(([key, value]) => `${key}=${value}`)
    .join('; ');
};

export const deleteCookie = (key: COOKIES_KEYS) => {
  const expires = ';expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
  (document as Document).cookie = `${key}=; ${expires}`;
};

export const getQueryParam = (queryKey: string): string => {
  const params = new URLSearchParams((location as Location).search);
  return params.get(queryKey) ?? '';
};

export const getAllQueryParams = (): string => {
  const params = new URLSearchParams((location as Location).search);
  return `?${params.toString()}`;
};

export const isWebview =
  Boolean(getQueryParam('mec')) ||
  Boolean(getQueryParam('jwe')) ||
  Boolean(getCookie(COOKIES_KEYS.IS_WEBVIEW));

export const Logout = () => {
  const navigateTo = useNavigate();
  const logoutApp = useCallback(() => {
    Object.values(COOKIES_KEYS).forEach((cookieKey) => deleteCookie(cookieKey));
    navigateTo('/login', { replace: true });
  }, [navigateTo]);
  return {
    logoutApp,
  };
};

export const webviewLoadingHandler = () => {
  // notifyPageLoaded();
  try {
    if ((window as any).webkit) {
      (window as any).webkit.messageHandlers.sendEndLoading.postMessage('');
    } else if ((window as any).AndroidHandler) {
      (window as any).AndroidHandler.sendEndLoading('');
    }
  } catch (e) {
    console.error(e);
  }
};
