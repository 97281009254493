import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEvents } from '../hooks/webapp-events/useEvents';
import { GetHomeInfoResponse, useGetHomeInfo } from '../services/useGetHomeInfo';
import { Endpoints, getHeadersDecrypt, getHeadersJWEAuth, serviceId } from '../util/constants';
import {
  COOKIES_KEYS,
  deleteCookie,
  getAllQueryParams,
  getCookie,
  getQueryParam,
  getUUID,
  setCookie,
  webviewLoadingHandler,
} from '../util/functions';

export type DecryptResponse = {
  result: number;
  description: string;
  access_token: string;
};

export const useRouterLogic = () => {
  const navigateTo = useNavigate();
  const { getHomeInfo } = useGetHomeInfo();
  const [mecSetted, setMec] = useState<string>('');
  const [jweSetted, setJWE] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const hasAccessToken = Boolean(getCookie(COOKIES_KEYS.ACCESS_TOKEN));
  const { jweFromQueryParam, mecFromQueryParam, pathFromQueryParam } = {
    mecFromQueryParam: getQueryParam('mec'),
    jweFromQueryParam: getQueryParam('jwe'),
    pathFromQueryParam: getQueryParam('path'),
  };

  // const { platformType, setPlatformType } = useGetHomeInfo();

  const hasAuthParameter = Boolean(mecFromQueryParam) || Boolean(jweFromQueryParam);
  const navigateToLoginWithParams = () =>
    navigateTo({
      pathname: '/login',
      search: getAllQueryParams(),
    });
  const { sendEvents } = useEvents();

  // Redirect user to bubble if he has plays available
  const redirectUserByAvailablePlays = (homeInfo: GetHomeInfoResponse) => {
    const { userAvailableTokens, isFirstLogin } = homeInfo;
    const webviewAccessRootRoute = !!userAvailableTokens ? '/bubble' : path;
    navigateTo(
      Boolean(webviewAccessRootRoute)
        ? {
            pathname: webviewAccessRootRoute,
            search: getAllQueryParams(),
          }
        : '/landing',
      {
        state: { isFirstLogin },
      },
    );
    setIsFetching(false);
  };

  const decryptExceptionHandler = () => {
    console.error('Decrypt error');
    navigateToLoginWithParams();
    setIsFetching(false);
  };

  useEffect(() => {
    if (!mecSetted) return;
    deleteCookie(COOKIES_KEYS.ACCESS_TOKEN);
    setCookie(COOKIES_KEYS.PLATFORM_TYPE, 'MEC_ENCRYPTED');
    // setPlatformType('mec-encrypted');
    fetch(Endpoints.decryptMec, {
      method: 'POST',
      headers: getHeadersDecrypt(mecSetted),
      credentials: 'omit',
      body: JSON.stringify({
        serviceId: serviceId,
        mec: mecSetted,
        transactionDate: Date.now(),
        transactionId: getUUID(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const { result, access_token }: DecryptResponse = response;
        if (result == 0 && access_token) {
          setCookie(COOKIES_KEYS.ACCESS_TOKEN, access_token);
          sendEvents({
            eventType: 'LOGIN_SUCCESS',
            triggerType: 'SUCCESS',
            subSource: 'webview',
            platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
          });
          return getHomeInfo();
        }
        sendEvents({
          eventType: 'LOGIN_ERROR',
          triggerType: 'FAILED',
          subSource: 'webview',
          responseData: JSON.stringify(response),
          platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
        });
        throw new Error('Error decrypt mec');
      })
      .then(redirectUserByAvailablePlays)
      .catch(decryptExceptionHandler)
      .finally(webviewLoadingHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mecSetted]);

  useEffect(() => {
    if (!jweSetted) return;

    deleteCookie(COOKIES_KEYS.ACCESS_TOKEN);
    setCookie(COOKIES_KEYS.PLATFORM_TYPE, 'WEBVIEW');
    // setPlatformType('webview');
    fetch(Endpoints.jweAuth, {
      method: 'POST',
      credentials: 'omit',
      headers: getHeadersJWEAuth(jweSetted),
    })
      .then((response) => response.json())
      .then((response) => {
        const { result, access_token }: DecryptResponse = response;
        if (result == 0 && access_token) {
          setCookie(COOKIES_KEYS.ACCESS_TOKEN, access_token);
          sendEvents({
            eventType: 'LOGIN_SUCCESS',
            triggerType: 'SUCCESS',
            subSource: 'webview',
            platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
          });

          return getHomeInfo();
        }
        sendEvents({
          eventType: 'LOGIN_ERROR',
          triggerType: 'FAILED',
          subSource: 'webview',
          responseData: JSON.stringify(response),
          platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
        });
        throw new Error('Error decrypt jwe');
      })
      .then(redirectUserByAvailablePlays)
      .catch(decryptExceptionHandler)
      .finally(webviewLoadingHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jweSetted]);

  useEffect(() => {
    const mapQueryParams = new Map<COOKIES_KEYS, string>([
      [COOKIES_KEYS.UTM_SOURCE, getQueryParam(COOKIES_KEYS.UTM_SOURCE)],
      [COOKIES_KEYS.UTM_CAMPAIGN, getQueryParam(COOKIES_KEYS.UTM_CAMPAIGN)],
      [COOKIES_KEYS.UTM_CONTENT, getQueryParam(COOKIES_KEYS.UTM_CONTENT)],
      [COOKIES_KEYS.UTM_MEDIUM, getQueryParam(COOKIES_KEYS.UTM_MEDIUM)],
      [COOKIES_KEYS.UTM_TERM, getQueryParam(COOKIES_KEYS.UTM_TERM)],
    ]);

    mapQueryParams.forEach((value, key) => {
      if (Boolean(value)) {
        setCookie(key, value);
      }
    });
  }, []);

  return {
    setPath,
    setMec,
    setJWE,
    setIsFetching,
    navigateToLoginWithParams,
    hasAuthParameter,
    isFetching,
    hasAccessToken,
    mecFromQueryParam,
    jweFromQueryParam,
    pathFromQueryParam,
  };
};
