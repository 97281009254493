import { useEffect, useRef } from 'react';
import {
  HorizontalScroll,
  Image,
  Inline,
  ProgressBar,
  skinVars,
  Text2,
  Text3,
  useWindowWidth,
} from '@telefonica/mistica';

import { monthlyProgress } from '../../home/useLogic';

type MonthlyNumbersTrackerProps = {
  accumulatedTopUps: number;
  monthlyTicketsTotal: number;
};

export const MonthlyNumbersTracker = ({
  accumulatedTopUps,
  monthlyTicketsTotal,
}: MonthlyNumbersTrackerProps) => {
  const { isDesktop } = {
    isDesktop: useWindowWidth() > 992,
  };

  const { months } = monthlyProgress(accumulatedTopUps, monthlyTicketsTotal);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const activeThresholdRef = useRef<HTMLDivElement>(null);
  const leftMarginOffset = 16;

  useEffect(() => {
    scrollContainerRef.current?.scrollBy({
      top: 0,
      left: (activeThresholdRef.current?.offsetLeft || leftMarginOffset) - leftMarginOffset,
      behavior: 'smooth',
    });
  }, []);

  return (
    <HorizontalScroll noScrollbar={!isDesktop} ref={scrollContainerRef}>
      {months?.map((month, i) => (
        <div
          className="progress-steps mx-2"
          key={i}
          ref={month.progress === 100 ? activeThresholdRef : null}
        >
          <Inline space={8} alignItems="center">
            <Text3
              medium
              color={
                month?.progress === 100
                  ? skinVars.colors.textPrimary
                  : skinVars.colors.textSecondary
              }
            >
              {month?.ticketsNumber}{' '}
            </Text3>
            <Image src={month?.imgTicket} width={18} noBorderRadius />
          </Inline>
          <ProgressBar progressPercent={month?.progress}></ProgressBar>
          <Text2
            regular
            color={
              month?.progress === 100 ? skinVars.colors.textPrimary : skinVars.colors.textSecondary
            }
          >
            {' '}
            {month?.label}
          </Text2>
        </div>
      ))}
    </HorizontalScroll>
  );
};
