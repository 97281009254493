import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonLayout,
  ButtonPrimary,
  Image,
  LoadingBar,
  MainSectionHeader,
  MainSectionHeaderLayout,
  MediaCard,
  ResponsiveLayout,
  skinVars,
  Stack,
  Text1,
  Text3,
  Text5,
  useWindowWidth,
} from '@telefonica/mistica';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';
import { Header } from '../Header/Header';

import { SectionHeaderWrapper, SectionWarningWrapper } from './how-it-work.styles';
import { useLogic } from './useLogic';

export const HowItWorkComponent = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'TUTORIAL_PAGE_ACCESS',
      triggerType: 'ACCESS',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);
  const { infoPortal } = useLogic();
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { isDesktop } = {
    isDesktop: useWindowWidth() > 992,
  };

  const howItWorkSubtitle = `Ao recarregar R$ 20 ou mais, você ganha prêmios na hora e participa de sorteios semanais. Acumulando R$ 30 em recargas, concorre ao prêmio especial de R$ 100 mil`;
  const warningAboutPrizes = `A Vivo NÃO entrega MOTOS como prêmio na Promoção Recarga Premiada e não informa códigos para que ganhadores validem sua premiação. Fique atento! Qualquer dúvida procure um dos canais oficiais da operadora.`;

  return (
    <>
      <Header title="Como funciona" selectedIndexProp={4}></Header>
      <SectionHeaderWrapper>
        <MainSectionHeaderLayout isInverse={!isDesktop}>
          <div className="left-text">
            <Box>
              <Stack space={16}>
                <MainSectionHeader
                  title=""
                  /*button={
                    isDesktop ? (
                      <SectionHeaderButtonWrapper>
                        <ButtonPrimary onPress={() => navigate('/home')}>
                          Começar jogo
                        </ButtonPrimary>
                      </SectionHeaderButtonWrapper>
                    ) : null
                  }*/
                />
                {!isDesktop && (
                  <Text5 color={skinVars.colors.textPrimaryInverse}>{howItWorkSubtitle}</Text5>
                )}
              </Stack>
            </Box>
          </div>
        </MainSectionHeaderLayout>
      </SectionHeaderWrapper>
      <SectionWarningWrapper>
        <div
          style={{
            backgroundColor: '#CC1E59',
          }}
        >
          <Box className="p-1">
            <Text1 regular color={skinVars.colors.textPrimaryInverse}>
              {warningAboutPrizes}
            </Text1>
          </Box>
        </div>
      </SectionWarningWrapper>
      <ResponsiveLayout>
        <Stack space={16}>
          <LoadingBar visible={displayLoading} />
          {isDesktop ? (
            <div style={{ textAlign: 'left' }}>
              <Box paddingBottom={24} paddingTop={48}>
                <Stack space={16}>
                  <Box className="my-3">
                    <Text5 color={skinVars.colors.textPrimary}>Como funciona</Text5>
                  </Box>
                  <Box>
                    <Text3 regular color={skinVars.colors.textSecondary}>
                      {howItWorkSubtitle}
                    </Text3>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Text5 color={skinVars.colors.textPrimary}>Veja como é fácil participar:</Text5>
              </Box>
            </div>
          ) : null}
          <Box paddingBottom={!isDesktop ? 24 : 80}>
            <Box
              padding={!isDesktop ? 16 : 0}
              className={!isDesktop ? 'row' : 'd-flex justify-content-between'}
            >
              {infoPortal?.map((panel: any, key) => (
                <div key={key} className="py-2 px-1 left-text col-12 col-md-3">
                  <MediaCard
                    title={panel.title}
                    description={panel.description}
                    media={
                      <Image
                        aspectRatio="16:9"
                        src={panel.image}
                        onLoad={() => setDisplayLoading(false)}
                      />
                    }
                  />
                </div>
              ))}
            </Box>
            {!isDesktop ? (
              <ButtonLayout align={'full-width'}>
                <ButtonPrimary
                  onPress={() => {
                    sendEvents({
                      eventType: 'BUTTON_CLICK',
                      triggerType: 'CLICK',
                      subSource: 'como-funciona',
                      subSubSource: 'Conferir Ganhadores',
                      eventCategory: isWebview ? 'webview' : 'web',
                      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                    });
                    navigate('/draws#new-winners');
                  }}
                >
                  Conferir Ganhadores
                </ButtonPrimary>
              </ButtonLayout>
            ) : null}
          </Box>
        </Stack>
        {isDesktop && (
          <ButtonPrimary
            style={{
              position: 'fixed',
              bottom: '40px',
              right: '50px',
              borderRadius: '100px',
              padding: '30px 0px',
            }}
            onPress={() => {
              sendEvents({
                eventType: 'BUTTON_CLICK',
                triggerType: 'CLICK',
                subSource: 'como-funciona',
                subSubSource: 'Conferir',
                eventCategory: isWebview ? 'webview' : 'web',
                platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
              });
              navigate('/draws#new-winners');
            }}
          >
            Conferir
          </ButtonPrimary>
        )}
      </ResponsiveLayout>
    </>
  );
};
