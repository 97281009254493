import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { getVivoSkin, ThemeConfig, ThemeContextProvider } from '@telefonica/mistica';

import './index.css';
import '@telefonica/mistica/css/mistica.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const misticaTheme: ThemeConfig = {
  skin: getVivoSkin(),
  i18n: { locale: 'pt-BR', phoneNumberFormattingRegionCode: 'BR' },
  colorScheme: 'light',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeContextProvider theme={misticaTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
