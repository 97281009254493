export const monthlyProgress = (topupsValue: number, ticketsTotal: number) => {
  const steps = [
    { value: 30, tickets: 1 },
    { value: 40, tickets: 2 },
    { value: 50, tickets: 4 },
    { value: 60, tickets: 8 },
    { value: 100, tickets: 16 },
    { value: 200, tickets: 32 },
    { value: 300, tickets: 64 },
  ];

  const ticketPurple = '/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-purple.svg';
  const ticketGray = '/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-gray.svg';

  const months = steps.map(({ value: stepValue, tickets: stepTickets }, i) => {
    // last step
    if (i === steps.length - 1) {
      return {
        ticketsNumber: topupsValue >= stepValue ? ticketsTotal : stepTickets,
        progress: topupsValue >= stepValue ? 100 : 0,
        label: `R$ ${topupsValue >= stepValue ? topupsValue : stepValue} ou +`,
        imgTicket: topupsValue >= stepValue ? ticketPurple : ticketGray,
      };
    }

    const nextStep = steps[i + 1];

    return {
      ticketsNumber:
        topupsValue >= stepValue && topupsValue < nextStep.value ? ticketsTotal : stepTickets,
      progress: topupsValue >= stepValue && topupsValue < nextStep.value ? 100 : 0,
      label: `R$ ${
        topupsValue >= stepValue && topupsValue < nextStep.value ? topupsValue : stepValue
      }`,
      imgTicket:
        topupsValue >= stepValue && topupsValue < nextStep.value ? ticketPurple : ticketGray,
    };
  });

  return {
    months,
  };
};

export const infoDrawsHome = () => {
  const updatedList = [
    {
      id: 1,
      image: '/apiCachefly/brasil/vivo/roblox/assets/banner_premio_final_02_v2.png',
      sectionTitle: 'Sorteio especial',
      title: 'Prêmio especial de R$ 100 mil',
      description:
        'Ficou ainda mais fácil concorrer ao prêmio especial de R$ 100 mil. Confira como participar do sorteio',
      btnText: 'Conferir Ganhadores',
      scope: 'INTERNAL',
      redirect: '/draws#new-winners',
    },
    {
      id: 2,
      image: '/apiCachefly/brasil/vivo/roblox/assets/banner_premios_semanal_v2.png',
      sectionTitle: 'Sorteios semanais',
      title: 'Tem prêmio toda semana!',
      description:
        'Com recargas de R$ 20 ou mais, você ganha números da sorte e concorre aos prêmios da semana',
      btnText: `Conferir Ganhadores`,
      scope: 'INTERNAL',
      redirect: `/draws#new-winners`,
    },
  ];

  return {
    listaSorteios: updatedList,
  };
};
