import { styled } from '@stitches/react';
import { skinVars } from '@telefonica/mistica';
import { Swiper } from 'swiper/react';

export const CustomSwiper: typeof Swiper = styled(Swiper, {
  '.swiper-button-prev, .swiper-button-next': {
    '--swiper-navigation-sides-offset': '24px',

    color: 'black',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    backgroundColor: skinVars.colors.background,
    border: `1px solid ${skinVars.colors.border}`,

    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',

    '&::after': {
      content: '',
    },

    '&.swiper-button-disabled': {
      display: 'none',
    },

    '@media (pointer: coarse)': {
      display: 'none',
    },
  },

  '.swiper-button-prev': {
    let: '24px',
    backgroundImage:
      'url(\'data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" role="presentation" xmlns="http://www.w3.org/2000/svg"><path fill="%23000000" d="M14.161 4.024c.235 0 .46.105.617.285a.928.928 0 01-.048 1.257L8 12.083l6.78 6.541c.343.335.22.9 0 1.108-.28.28-.82.392-1.163.08l-.02-.018-7.323-7.046A.905.905 0 016 12.095a.913.913 0 01.268-.658l7.325-7.176a.803.803 0 01.568-.237z"></path></svg>\')',
  },

  '.swiper-button-next': {
    backgroundImage:
      'url(\'data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" role="presentation" xmlns="http://www.w3.org/2000/svg"><path fill="%23000000" d="M9.839 20a.823.823 0 01-.617-.285.928.928 0 01.048-1.256L16 11.94 9.22 5.4c-.343-.335-.22-.9 0-1.108.28-.28.82-.392 1.163-.08l.02.018 7.323 7.046a.905.905 0 01.274.654.913.913 0 01-.268.658l-7.325 7.175A.803.803 0 019.84 20z"></path></svg>\')',
  },
});

export const CloseButton = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: '#626064',
  position: 'absolute',
  height: '32px',
  width: '32px',
  right: '15px',
  top: '15px',
  zIndex: '10',
  fill: '#ffffff',
});
