import { useState } from 'react';
import { Box, ButtonLayout, ButtonPrimary, ButtonSecondary, TextLink } from '@telefonica/mistica';

import { privacyPolicyUrl } from '../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview, setCookie } from '../../util/functions';

import { CookiesPolicyContainer, CookiesPolicyDescription } from './styles';

export const CookiesPolicy = () => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const { isFirstLogin, cookiesPolicyDisplayed } = {
    isFirstLogin: Boolean(getCookie(COOKIES_KEYS.IS_FIRST_LOGIN)),
    cookiesPolicyDisplayed: Boolean(getCookie(COOKIES_KEYS.COOKIES_POLICY_DISPLAYED)),
  };

  if ((!isShown && !isFirstLogin) || cookiesPolicyDisplayed || isWebview) {
    return null;
  }

  const cookiesPolicyActionHandler = () => {
    setCookie(COOKIES_KEYS.COOKIES_POLICY_DISPLAYED, 'true');
    setIsShown(false);
  };

  return (
    <CookiesPolicyContainer paddingX={16} paddingY={32}>
      <Box className="left-text mb-5">
        <CookiesPolicyDescription regular>
          Usamos cookies para garantir que você obtenha a melhor experiência em nosso site. Ao
          clicar em &quot;Aceitar&quot;, você concorda com o uso de cookies, de acordo com a nossa
          {/* <TextLink href={cookiesPolicyUrl} newTab>
            {' '}
            política de cookies
          </TextLink>{' '}
          e */}{' '}
          <TextLink href={privacyPolicyUrl} newTab>
            política de privacidade
          </TextLink>{' '}
        </CookiesPolicyDescription>
      </Box>
      <Box>
        <ButtonLayout align="center">
          <ButtonSecondary onPress={() => cookiesPolicyActionHandler()}>
            Rejeitar cookies
          </ButtonSecondary>
          <ButtonPrimary onPress={() => cookiesPolicyActionHandler()}>
            Aceitar cookies
          </ButtonPrimary>
        </ButtonLayout>
      </Box>
    </CookiesPolicyContainer>
  );
};
