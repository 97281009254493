import { useCallback, useState } from 'react';

import { Endpoints, getHeadersTicket } from '../../util/constants';

import { Ticket } from './type';

export const GetTicketsApi = () => {
  const [tickets, setTickets] = useState<Ticket[] | []>([]);
  const [isFetchitTicketsList, setIsFetchitTicketsList] = useState<boolean>(true);

  const getTicketsList = useCallback(
    (drawId: number) =>
      fetch(Endpoints.getTicketsByDraw, { headers: getHeadersTicket(drawId) })
        .then((response) => response.json())
        .then((json) => {
          setTickets(json.DrawTicketTO);
          return json.DrawTicketTO;
        })
        .finally(() => setIsFetchitTicketsList(false)),
    [],
  );

  return {
    tickets,
    isFetchitTicketsList,
    getTicketsList,
  };
};
