import { styled } from '@stitches/react';
import { skinVars } from '@telefonica/mistica';

export const SectionHeaderWrapper = styled('div', {
  '@media(min-width: 992px)': {
    background: 'url(/apiCachefly/brasil/vivo/roblox/assets/header_onboarding_v32.png)',
    backgroundColor: skinVars.colors.backgroundBrand,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    height: '220px',
  },
  '@media(min-width: 1100px)': {
    height: '240px',
  },
  '@media(min-width: 1224px)': {
    height: '290px',
  },
  '@media(min-width: 1369px)': {
    // height: '320px',
    backgroundSize: '1224px auto',
  },
});

export const SectionWarningWrapper = styled('div', {
  '@media(min-width: 1369px)': {
    margin: '0px auto',
    width: '1224px',
  },
});

export const SectionHeaderButtonWrapper = styled('div', {
  margin: '140px auto 0 100px',
  '@media(min-width: 1024px)': {
    marginTop: '115px',
    marginLeft: '100px',
  },
  '@media(min-width: 1100px)': {
    marginTop: '135px',
    marginLeft: '160px',
  },
  '@media(min-width: 1224px)': {
    marginTop: '170px',
  },
  '@media(min-width: 1369px)': {
    marginTop: '175px',
    marginLeft: '60px',
  },
});
