import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  IconChevronRightRegular,
  Inline,
  skinVars,
  Stack,
  Text1,
  Text2,
  Text4,
  useScreenSize,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../../App.styles';
import { useEvents } from '../../../hooks/webapp-events/useEvents';
import { DrawInfoTo, useGetHomeInfo } from '../../../services/useGetHomeInfo';
import { formatDate } from '../../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../util/functions';
import { LoadingRecargaPremiada } from '../../loading/loading';
import { LuckyNumbersContainer } from '../styles';
import { DrawToList } from '../useLogic';

import { MonthlyNumbersTracker } from './monthly-numbers-tracker';
import { CardSpecialDrawDetails } from '.';

type GrandePremioProps = {
  draws: DrawToList[];
};

export const GrandePremio = ({ draws }: GrandePremioProps) => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'DRAW_PAGE_ACCESS',
      triggerType: 'access',
      subSource: 'grande-premio',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const navigateTo = useNavigate();
  const { homeInfo, isFetchingHomeInfo } = useGetHomeInfo();
  const { drawInfoTO, accumulatedMonthlyTickets, accumulatedTopUps, appTickets } = homeInfo;
  const drawInfoListFiltered: DrawInfoTo[] = drawInfoTO
    ? drawInfoTO.filter((draw: DrawInfoTo) => draw.drawGroup === 4)
    : [];
  // drawInfoTO.filter((draw: DrawInfoTo) => draw.drawGroup === 4) ?? [];

  const monthlyTicketsTotal = accumulatedMonthlyTickets + appTickets;

  const { isTabletOrBigger } = useScreenSize();

  return (
    <Stack space={0}>
      <Stack space={16} className="left-text">
        {draws.length && (
          <Stack space={16}>
            {draws
              .filter((draw) => draw.drawGroup === 4)
              .map((draw) => (
                <CardSpecialDrawDetails key={draw.drawId} drawDetails={draw} />
              ))}
          </Stack>
        )}
      </Stack>
      {isFetchingHomeInfo ? (
        <LoadingRecargaPremiada visible={true} />
      ) : (
        <Box className="left-text my-3">
          <Box className="subTitleWrapper mb-3">
            <Text4 light>Participe do sorteio especial</Text4>
          </Box>
          <Box className="descriptionWrapper mb-4">
            <Text2 regular color={skinVars.colors.textSecondary}>
              Acumulando R$ 30 ou mais em recargas, você ganha números da sorte para participar do
              sorteio especial de R$ 100 mil
              <br />
              Quanto mais você recarrega, mais chances tem de ganhar!
            </Text2>
          </Box>
          <div>
            <MonthlyNumbersTracker
              monthlyTicketsTotal={monthlyTicketsTotal}
              accumulatedTopUps={accumulatedTopUps}
            />
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Box paddingTop={32} paddingBottom={12}>
                <Text4 light>Confira os seus números da sorte</Text4>
              </Box>
              <div
                onClick={() => {
                  sendEvents({
                    eventType: 'BUTTON_CLICK',
                    triggerType: 'CLICK',
                    subSource: 'mensal',
                    subSubSource: 'Meus números da sorte',
                    platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                  });
                  navigateTo(`/tickets#Sorteio especial`);
                }}
              >
                <LuckyNumbersContainer>
                  <Inline space={12} alignItems="center">
                    <img
                      className="mr-3"
                      src="/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-bg-purple.png"
                    />
                    <>
                      <Text2 medium color={skinVars.colors.textPrimary}>
                        {monthlyTicketsTotal} Número
                        {monthlyTicketsTotal > 1 ? 's' : ''} da sorte
                      </Text2>
                      <br />
                      {isTabletOrBigger && (
                        <Text1 regular color={skinVars.colors.textSecondary}>
                          Para o sorteio especial
                        </Text1>
                      )}
                    </>
                  </Inline>
                  <Box>
                    <IconChevronRightRegular />
                  </Box>
                </LuckyNumbersContainer>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <Box className="left-text">
                <Box paddingTop={isTabletOrBigger ? 32 : 12} paddingBottom={12}>
                  <Text4 light>Data do sorteio</Text4>
                </Box>
                {drawInfoListFiltered.length
                  ? drawInfoListFiltered.map((draw: DrawInfoTo, i) => (
                      <FlexBoxLeft
                        key={i}
                        className="left-text border-item"
                        // css={{
                        //   borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                        //   padding: '12px 0px',
                        // }}
                      >
                        <Stack space={0}>
                          <Text2 medium transform="capitalize" color={skinVars.colors.textPrimary}>
                            Sorteio especial
                          </Text2>
                          <Text1 regular color={skinVars.colors.textSecondary}>
                            Período de participação:&nbsp;
                            {formatDate(draw.drawStartDate, 'day-month-year-numeric')}
                            &nbsp;a&nbsp;
                            {formatDate(draw.drawEndDate, 'day-month-year-numeric')}
                          </Text1>
                          <Text1 regular color={skinVars.colors.textSecondary}>
                            Data do sorteio:&nbsp;
                            {formatDate(draw.drawDate, 'day-month-year-numeric')}
                          </Text1>
                        </Stack>
                      </FlexBoxLeft>
                    ))
                  : null}
              </Box>
            </div>
          </div>
        </Box>
      )}
    </Stack>
  );
};

export default GrandePremio;
