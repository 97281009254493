import { styled } from '@stitches/react';
import { Box, skinVars } from '@telefonica/mistica';

export const ButtonsWrapper = styled(Box, {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  flexFlow: 'row wrap',
  paddingTop: '24px',
  button: {
    width: '100%!important',
    '&:first-of-type': {
      margin: '10px 0 16px',
    },
  },
  '@media(min-width: 992px)': {
    width: 'calc(50% - 12px)',
    button: {
      width: 'calc(50% - 13px)!important',
      '&:first-of-type': {
        margin: '0 24px 0 0',
      },
    },
  },
});

export const DesktopHomeBanner = styled('div', {
  // background: 'url(/apiCachefly/brasil/vivo/roblox/assets/home-banner-lg.svg)',
  background: 'url(/apiCachefly/brasil/vivo/roblox/assets/home_banner_lg_v2.1.png)',
  backgroundSize: 'cover',
  backgroundColor: skinVars.colors.backgroundBrand,
  height: '240px',
  display: 'none',
  position: 'relative',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  '@media(min-width: 992px)': {
    display: 'block',
    height: '260px',
  },
  '@media(min-width: 1080px)': {
    height: '280px',
  },
  '@media(min-width: 1190px)': {
    height: '310px',
  },
  '@media(min-width: 1366px)': {
    height: '340px',
  },
  '@media(min-width: 1680px)': {
    // height: '280px',
    backgroundSize: '1680px auto',
  },
});

export const MobileHomeBanner = styled('img', {
  width: '100%',
  maxHeight: '300px',
  minHeight: '150px',
  objectFit: 'cover',
  display: 'block',
  backgroundColor: skinVars.colors.backgroundBrand,

  '@media(min-width: 992px)': {
    display: 'none',
  },
});

export const ContestGrid = styled('div', {
  display: 'grid',
  grid: 'auto-flow / 1fr',
  gap: '16px',
  alignItems: 'start',

  '@media(min-width: 768px)': {
    alignItems: 'stretch',
    grid: 'auto-flow / 1fr 1fr',
  },
});
