import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@telefonica/mistica';

import './App.css';

import { CookiesPolicy } from './components/Cookies/CookiesPolicy';
import { useEvents } from './hooks/webapp-events/useEvents';
import { isWebview, webviewLoadingHandler } from './util/functions';
import { AppContainer } from './App.styles';
import { AppRoutes } from './routes';

export const App = () => {
  const { sendEvents } = useEvents();

  useEffect(() => {
    sendEvents({
      eventType: 'APP_INIT',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
    });
    webviewLoadingHandler();
  });

  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AppContainer>
      <Box>
        <AppRoutes />
        <CookiesPolicy />
      </Box>
    </AppContainer>
  );
};

export default App;
