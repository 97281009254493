import { useEffect, useRef } from 'react';
import {
  Chip,
  HorizontalScroll,
  Inline,
  RadioButton,
  RadioGroup,
  skinVars,
  Text2,
  useScreenSize,
} from '@telefonica/mistica';

type SelectorProps = {
  values: string[];
  valueSelected: string;
  onChange: (_value: string) => void;
};

export const Selector = ({ values, valueSelected, onChange }: SelectorProps) => {
  const { isMobile } = useScreenSize();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const activeElementRef = useRef<HTMLDivElement>(null);

  const leftMarginOffset = 16;

  useEffect(() => {
    scrollContainerRef.current?.scrollBy({
      top: 0,
      left: (activeElementRef.current?.offsetLeft || leftMarginOffset) - leftMarginOffset,
      behavior: 'smooth',
    });
  }, []);

  return (
    <RadioGroup name="selector-group" value={valueSelected} onChange={onChange}>
      <HorizontalScroll noScrollbar ref={scrollContainerRef}>
        <Inline fullWidth={isMobile} space={16} alignItems="center" wrap>
          {values.map((value: string) => (
            <div key={value} ref={valueSelected === value ? activeElementRef : null}>
              <RadioButton
                value={value}
                render={({ checked }) => (
                  <Chip active={checked} id={value}>
                    <Text2
                      color={
                        checked ? skinVars.colors.backgroundBrand : skinVars.colors.textSecondary
                      }
                      regular
                      transform="capitalize"
                    >
                      {value}
                    </Text2>
                  </Chip>
                )}
              />
            </div>
          ))}
        </Inline>
      </HorizontalScroll>
    </RadioGroup>
  );
};
