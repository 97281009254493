export const useLogic = () => {
  const onboardingSteps = [
    {
      image: '/apiCachefly/brasil/vivo/roblox/assets/onboarding01.svg',
      title: `Recarregue e ganhe prêmios na hora`,
      description:
        'Ao recarregar R$ 20 ou mais, você joga no Estoura Bolhas e ganha um prêmio na mesma hora! Recarregue o quanto quiser',
    },
    {
      image: '/apiCachefly/brasil/vivo/roblox/assets/onboarding02.2.svg',
      title: `Ganhe prêmios nos sorteios semanais`,
      description:
        'Ao recarregar R$ 20 ou mais, você ganha 1 número da sorte e participa dos sorteios todas as semanas',
    },
    {
      image: '/apiCachefly/brasil/vivo/roblox/assets/onboarding03.2.svg',
      title: 'Concorra a R$ 100 mil no sorteio especial',
      description:
        'Acumulando R$ 30 ou mais em recargas, você também ganha números da sorte para o sorteio especial',
    },
    {
      image: '/apiCachefly/brasil/vivo/roblox/assets/onboarding04.2.svg',
      title: 'Ganhe um número da sorte extra no app',
      description:
        'Acessando a Recarga Premiada pelo app Vivo, você garante um número da sorte extra para o sorteio especial',
    },
  ];
  return {
    onboardingSteps,
  };
};
