import { useNavigate } from 'react-router';
import {
  Box,
  IconChevronRightRegular,
  ResponsiveLayout,
  skinVars,
  Text3,
  Text4,
  TextLink,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../App.styles';
import { Header } from '../Header/Header';

export const RegComponent = () => {
  const navigateTo = useNavigate();

  return (
    <>
      <Header title="Regulamento" selectedIndexProp={6}></Header>
      <ResponsiveLayout>
        <div className="my-3 mx-3">
          <Box className="left-text">
            <TextLink
              className="my-3"
              onPress={() => {
                navigateTo('/home');
              }}
            >
              Voltar para home
            </TextLink>
            <br></br>
            <Text4 regular>Regulamento</Text4>
          </Box>
          <Box className="my-4">
            <FlexBoxLeft
              className="left-text justify-content-between"
              css={{
                borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                padding: '12px 30px',
              }}
              onClick={() => {
                window.open(
                  '/apiCachefly/brasil/vivo/roblox/documentos_legais/RG TimWe Vivo 2 ST Aprovado_2410.pdf',
                  '_blank',
                );
              }}
            >
              <div className="d-grid">
                <Text3 regular>Regulamento Recarga Premiada</Text3>
                <Text3 regular>Assemelhado ao sorteio</Text3>
              </div>

              <Box>
                <IconChevronRightRegular></IconChevronRightRegular>
              </Box>
            </FlexBoxLeft>
            <FlexBoxLeft
              className="left-text justify-content-between"
              css={{
                borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                padding: '12px 30px',
              }}
              onClick={() => {
                window.open(
                  '/apiCachefly/brasil/vivo/roblox/documentos_legais/RG TimWe Vivo 2 VB Aprovado_2410.pdf',
                  '_blank',
                );
              }}
            >
              <div className="d-grid">
                <Text3 regular>Regulamento Recarga Premiada</Text3>
                <Text3 regular>Assemelhado a Vale-Brinde</Text3>
              </div>
              <Box>
                <IconChevronRightRegular></IconChevronRightRegular>
              </Box>
            </FlexBoxLeft>
          </Box>
        </div>
      </ResponsiveLayout>
    </>
  );
};
