import { Box, Image, skinVars, Text1, Text2, Text4 } from '@telefonica/mistica';

import { FlexBoxLeft } from '../../../../App.styles';
import { PrizeTOList } from '../premios-disponiveis/useLogic';

import { PremioImageWrapper } from './styles';

export type PrizeProps = {
  prize: PrizeTOList;
};

export const PrizeDetails = ({
  prize: { cacheFlyUrl, prizeName, prizeInfo, prizeDefId, prizeDescription },
}: PrizeProps) => (
  <FlexBoxLeft className="border-item">
    <PremioImageWrapper>
      <Image src={cacheFlyUrl} width={80} noBorderRadius={false} aspectRatio={0}></Image>
    </PremioImageWrapper>
    <Box className="left-text align-items-center pl-3">
      <Text4 medium>{prizeDefId !== '2' ? prizeName : prizeDescription}</Text4>
      <br></br>
      <Box>
        {!!prizeDescription && prizeDescription !== prizeName && prizeDefId !== '2' && (
          <Box>
            <Text2 regular>{prizeDescription}</Text2>
            <br></br>
          </Box>
        )}
        {!!prizeInfo && (
          <Text1 regular color={skinVars.colors.textSecondary}>
            {prizeInfo}
          </Text1>
        )}
      </Box>
    </Box>
  </FlexBoxLeft>
);
