import { styled } from '@stitches/react';
import { Box, Text3 } from '@telefonica/mistica';

export const CookiesPolicyContainer = styled(Box, {
  background: '#E5E5E5',
  position: 'fixed',
  bottom: '0',
  zIndex: 999999,
  '@media(min-width: 1024px)': {
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '5px 10px!important',
  },
});

export const CookiesPolicyDescription = styled(Text3, {
  lineHeight: '24px',
  fontSize: '16px',
});
