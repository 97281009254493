import { useEffect, useState } from 'react';
import {
  Box,
  ButtonPrimary,
  FeedbackScreen,
  IconTicketsRegular,
  IconWarningLight,
  ResponsiveLayout,
  skinVars,
  Stack,
  Text2,
  Text3,
  Text4,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../App.styles';
import { useEvents } from '../../hooks/webapp-events/useEvents';
import { recargaUrlMobile, recargaUrlWeb } from '../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';
import { DrawsContentApi } from '../draws-content/useLogic';
import { DesktopHomeBanner } from '../home/components/home.styles';
import { LoadingRecargaPremiada } from '../loading/loading';
import { Selector } from '../Selector/Selector';

import { Ticket } from './type';
import { GetTicketsApi } from './useLogic';

export const TicketsComponent = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'TICKETS_HISTORY_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);
  const fragment: string = decodeURI(window.location.hash.substring(1));

  const { isFetchitTicketsList, getTicketsList } = GetTicketsApi();
  const { draws: fetchedDraws } = DrawsContentApi();
  const [selectedDraw, setSelectedDraw] = useState<string>(fragment || '1º Sorteio');
  const [ticketsHistory] = useState<{ [drawName: string]: Ticket[] }>({});
  const [draws, setDraws] = useState<string[]>([]);
  const recargaUrl = isWebview ? recargaUrlMobile : recargaUrlWeb;

  const redirectRecarga = () => {
    sendEvents({
      eventType: 'USER_REDIRECT',
      triggerType: 'CLICK',
      redirectUrl: recargaUrl,
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
    window.open(recargaUrl, '_blank');
  };

  useEffect(() => {
    if (fetchedDraws.length) {
      fetchedDraws.forEach((draw) => {
        if (draw.drawGroup === 3 || draw.drawGroup === 4) {
          // @todo handle this in a more graceful way
          if (draw.drawName === 'R$100mil') {
            draw.drawName = 'Sorteio especial';
          }

          if (!draws.includes(draw.drawName)) {
            draws.push(draw.drawName);
          }

          getTicketsList(draw.drawId).then((ticketsByDrawId) => {
            ticketsHistory[draw.drawName] = [];
            ticketsHistory[draw.drawName].push(...ticketsByDrawId);
          });
        }
      });
      setDraws(draws);
    }
  }, [fetchedDraws]);

  return (
    <>
      <DesktopHomeBanner></DesktopHomeBanner>
      <ResponsiveLayout>
        <div className="my-4" style={{ textAlign: 'left' }}>
          <Stack space={12}>
            <Text4 light color={skinVars.colors.textPrimary}>
              Seus números da sorte
            </Text4>
            <Text2 regular color={skinVars.colors.textSecondary}>
              Confira seus números da sorte para os sorteios
            </Text2>
          </Stack>
          {isFetchitTicketsList ? (
            <LoadingRecargaPremiada visible={isFetchitTicketsList}></LoadingRecargaPremiada>
          ) : (
            <Box>
              <Box paddingY={16}>
                <Selector
                  values={draws.sort()}
                  valueSelected={selectedDraw}
                  onChange={setSelectedDraw}
                />
              </Box>
              {!ticketsHistory[selectedDraw]?.length ? (
                <FeedbackScreen
                  title="Você ainda não tem números da sorte para o sorteio"
                  description="Recarregue R$ 20 ou mais para participar do sorteio semanal e acumule R$ 30 ou mais em recargas para participar do sorteio especial de R$ 100mil"
                  children={
                    <Stack space={24}>
                      <ButtonPrimary onPress={redirectRecarga}>Fazer recarga</ButtonPrimary>
                    </Stack>
                  }
                  icon={<IconWarningLight size="65px" color={skinVars.colors.backgroundBrand} />}
                  animateText
                />
              ) : null}
              {ticketsHistory[selectedDraw]?.map((ticket: any) => (
                <FlexBoxLeft
                  key={`${ticket?.luckyNumber}`}
                  className="left-text"
                  css={{
                    borderBottom: `1px solid ${skinVars.colors.borderLow}`,
                    padding: '16px 0',
                  }}
                >
                  <Box>
                    <IconTicketsRegular></IconTicketsRegular>
                  </Box>
                  <Box paddingLeft={16}>
                    <Text3 regular color={skinVars.colors.textPrimary}>
                      Nº {ticket?.luckyNumber}
                    </Text3>
                    {/*<br />
                    <Text1 regular color={skinVars.colors.textSecondary}>
                      Número serie: {ticket?.serie}
                    </Text1>*/}
                  </Box>
                </FlexBoxLeft>
              ))}
            </Box>
          )}
        </div>
      </ResponsiveLayout>
    </>
  );
};
