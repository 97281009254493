import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonLayout,
  ButtonPrimary,
  IconCloseRegular,
  PageBullets,
  skinVars,
  Stack,
  Text3,
  Text7,
} from '@telefonica/mistica';
import { Autoplay, Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { Swiper as TSwiper } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/navigation';

import { DesktopHomeBanner } from '../home/components/home.styles';

import { CloseButton, CustomSwiper } from './styles';
import { useLogic } from './useLogic';

export const OnBoardingComponent = () => {
  const navigate = useNavigate();
  const { onboardingSteps } = useLogic();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [swiperInstance, setSwiperInstance] = useState<TSwiper>(null);

  const changeSlide = () => {
    swiperInstance && swiperInstance.slideNext();
  };

  return (
    <>
      <DesktopHomeBanner></DesktopHomeBanner>
      <Box>
        <CloseButton onClick={() => navigate('/home')}>
          <IconCloseRegular color={skinVars.colors.textPrimaryInverse} />
        </CloseButton>
        <Stack space={16} className="onboarding-container">
          <CustomSwiper
            modules={[Autoplay, Navigation]}
            speed={500}
            autoplay={{ delay: 3000, disableOnInteraction: false, stopOnLastSlide: true }}
            navigation={true}
            simulateTouch={false}
            onSlideChange={(swiper: TSwiper) => setCurrentPage(swiper.activeIndex)}
            onSwiper={setSwiperInstance}
          >
            {onboardingSteps.map((step: any, i) => (
              <SwiperSlide key={i}>
                <img src={step.image} className=""></img>
                {/* <Image src={step.image} width="400"></Image> */}
                <Box padding={16} paddingBottom={32} className="center-text">
                  <Stack space={16}>
                    <Text7>{step.title}</Text7>
                    <Text3 regular>{step.description}</Text3>
                  </Stack>
                </Box>
              </SwiperSlide>
            ))}
          </CustomSwiper>
          <PageBullets currentIndex={currentPage} numPages={onboardingSteps.length}></PageBullets>
          <Box padding={16}>
            <ButtonLayout align={'full-width'}>
              {currentPage === 3 ? (
                <ButtonPrimary onPress={() => navigate('/home')}>Começar jogo</ButtonPrimary>
              ) : (
                <ButtonPrimary onPress={() => changeSlide()}>Continuar</ButtonPrimary>
              )}
            </ButtonLayout>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
