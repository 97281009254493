import { styled } from '@stitches/react';
import { Box, ButtonPrimary, ButtonSecondary, skinVars } from '@telefonica/mistica';

export const LandingDescription = styled('span', {
  color: skinVars.colors.textPrimaryInverse,
  textAlign: 'left!important',
  fontSize: '18px',
  lineHeight: '24px',
  width: '100%',
  display: 'block',
});

export const LandingCampaignOverInfo = styled('div', {
  background: skinVars.colors.feedbackErrorBackground,
  color: skinVars.colors.textPrimaryInverse,
  fontSize: '18px',
  lineHeight: '24px',
  padding: '24px 16px',
});

export const LandingInfoWrapper = styled(Box, {
  backgroundImage: 'url(/apiCachefly/brasil/vivo/roblox/assets/landing-main-img_v2.1.png)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: '0',
  backgroundPositionX: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexFlow: 'row wrap',
  bottom: 0,
  width: '100vw',
  height: '90vh',
  alignContent: 'flex-end',

  '& .landing-footer': {
    padding: '24px 16px',
    width: '100%',
    background: skinVars.colors.backgroundBrand,
  },

  variants: {
    firstLogin: {
      true: {
        backgroundImage: 'url(/apiCachefly/brasil/vivo/roblox/assets/landing-firstLogin.png)',
        backgroundSize: 'cover',
        '@media(max-height: 750px)': {
          backgroundPositionY: '-30px',
        },
        '@media(max-height: 700px)': {
          backgroundPositionY: '-110px',
        },
        '& .landing-footer': {
          background: 'none',
        },
      },
    },
  },

  defaultVariants: {
    firstLogin: false,
  },
});

export const StartGameButton = styled(ButtonPrimary, {
  width: '100%',
  height: '48px',
  display: 'block',
  background: skinVars.colors.buttonPrimaryBackgroundInverse,
  border: 'solid 1.5px #fff',
  color: skinVars.colors.textButtonPrimaryInverse,
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '24px',
  fontWeight: 500,

  variants: {
    firstLogin: {
      true: {
        background: skinVars.colors.buttonPrimaryBackground,
        border: 0,
        color: skinVars.colors.textButtonPrimary,
      },
    },
  },

  defaultVariants: {
    firstLogin: false,
  },
});

export const KnowHowItWorks = styled(ButtonSecondary, {
  width: '100%',
  height: '48px',
  display: 'block',
  border: 'solid 1.5px #fff',
  color: skinVars.colors.textButtonSecondaryInverse,
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '16px',
  fontWeight: 500,
});

export const ContainerBgVivo = styled('div', {
  display: 'flex',
  background: skinVars.colors.backgroundBrand,
  alignItems: 'flex-start',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
});
