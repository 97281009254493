import { Box, Image, skinVars } from '@telefonica/mistica';

import { CardDraw, CardDrawSubtitle, CardDrawTitle } from '../../../App.styles';
import { CardSpecialDraw, CardSpecialDrawDescription, CardSpecialDrawImage } from '../styles';

// export type PrizeProps = {
//   prize: PrizeTOList;
// };

export const CardDrawDetails = ({ drawDetails }: any) => (
  <CardDraw className="test-example">
    <Box className="left-text pt-5 pl-3">
      <CardDrawTitle color={skinVars.colors.textPrimaryInverse}>
        {drawDetails.drawName}
      </CardDrawTitle>
      <CardDrawSubtitle color={skinVars.colors.textPrimaryInverse}>
        {drawDetails.drawDescription}
      </CardDrawSubtitle>
    </Box>
    <Box className="img-card-draw-final">
      <Image src={drawDetails?.drawImgUrl} width={120} aspectRatio={0}></Image>
    </Box>
  </CardDraw>
);

export const CardSpecialDrawDetails = ({ drawDetails }: any) => (
  <CardSpecialDraw>
    <CardSpecialDrawDescription className="left-text pt-5 pl-3">
      <CardDrawSubtitle color={skinVars.colors.textPrimaryInverse}>
        {drawDetails?.drawDescription}
      </CardDrawSubtitle>
      <CardDrawTitle color={skinVars.colors.textPrimaryInverse}>
        {drawDetails?.drawName}
      </CardDrawTitle>
    </CardSpecialDrawDescription>
    <CardSpecialDrawImage>
      <img src={drawDetails?.drawImgUrl} />
      {/*<Image src={drawDetails?.drawImgUrl} width={190} aspectRatio={0}></Image>*/}
    </CardSpecialDrawImage>
  </CardSpecialDraw>
);
