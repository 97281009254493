import Bubble from '../components/bubble/bubble';
import Draws from '../components/Draws/Draws';
import { HistoricoRecargasComponent } from '../components/historico-recargas';
import Home from '../components/home/home';
import Premios from '../components/Premios/Premios';
import { TicketsComponent } from '../components/tickets';

export const PRIVATE_ROUTES = [
  {
    publicRoute: false,
    path: '/home',
    component: Home,
    title: 'Início',
  },
  {
    publicRoute: false,
    path: '/historico-recargas',
    component: HistoricoRecargasComponent,
    title: 'Início',
  },
  {
    publicRoute: false,
    path: '/bubble',
    component: Bubble,
    title: 'Estoura Bolha',
  },
  {
    publicRoute: false,
    path: '/draws',
    component: Draws,
    title: 'Sorteios',
  },
  {
    publicRoute: false,
    path: '/tickets',
    component: TicketsComponent,
    title: 'Sorteios',
  },
  {
    publicRoute: false,
    path: '/premios',
    component: Premios,
    title: 'Prêmios',
  },
];
