import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dialog, Image } from '@telefonica/mistica';

import { FlexBoxCenter } from '../../App.styles';
import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie } from '../../util/functions';

import { BubbleGameSteps } from './bubble';
import { RedeemResponse } from './useLogic';

type PopupSuccessProps = {
  responseRedeem: RedeemResponse;
  setGameStep: (_step: BubbleGameSteps) => void;
};

export const useDialogSuccess = () => {
  const { sendEvents } = useEvents();
  const [isDialogOpenned, setIsDialogOpenned] = useState<boolean>(false);
  const navigateTo = useNavigate();

  const dialogSuccess = useCallback(
    ({
      responseRedeem: { cacheFlyUrl, prizeName, message, availableCredits, prizeDescription },
      setGameStep,
    }: PopupSuccessProps) => {
      if (isDialogOpenned) return;
      setIsDialogOpenned(true);

      const { isFirstLogin, isWebview } = {
        isFirstLogin: Boolean(getCookie(COOKIES_KEYS.IS_FIRST_LOGIN)),
        isWebview: Boolean(getCookie(COOKIES_KEYS.IS_WEBVIEW)),
      };

      const cancelText = isFirstLogin ? 'Conferir outros prêmios' : 'Ir para o início';

      const acceptButtonAction = () => {
        sendEvents({
          eventType: 'BUTTON_CLICK',
          triggerType: 'CLICK',
          subSource: 'Estoura bolha',
          subSubSource: 'Estourar outra bolha',
          eventCategory: isWebview ? 'webview' : 'web',
        });
        setIsDialogOpenned(false);
        if (availableCredits) {
          setGameStep('step1');
        } else {
          navigateTo(isWebview && isFirstLogin ? '/landing' : '/home', {
            state: { isFirstLogin },
          });
        }
      };

      const cancelButtonAction = () => {
        sendEvents({
          eventType: 'BUTTON_CLICK',
          triggerType: 'CLICK',
          subSource: 'Estoura bolha',
          subSubSource: cancelText,
          eventCategory: isWebview ? 'webview' : 'web',
          platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
        });
        setIsDialogOpenned(false);
        navigateTo(isFirstLogin && !availableCredits ? '/landing' : '/home', {
          state: { isFirstLogin },
        });
      };

      dialog({
        className: `text-center bubble-success-dialog ${
          !availableCredits ? 'popup-success_hidden-accept' : ''
        }`,
        icon: (
          <FlexBoxCenter>
            <Image src={cacheFlyUrl} width={100} aspectRatio={1}></Image>
          </FlexBoxCenter>
        ),
        subtitle: prizeName == 'Sem Premio' ? 'Ops! Não foi dessa vez' : 'Parabéns!',
        message:
          prizeName == 'Sem Premio'
            ? 'Mas não desanime, você terá a chance de ganhar muitos prêmios, a promoção ainda não acabou.'
            : `Você ganhou ${prizeDescription}! ${message}`,
        acceptText: 'Estourar outra bolha',
        cancelText,
        showCancel: true,
        showClose: false,
        onAccept: acceptButtonAction,
        onCancel: cancelButtonAction,
      });
    },
    [isDialogOpenned, navigateTo],
  );

  return { dialogSuccess };
};
