import { styled } from '@stitches/react';
import { Text1 } from '@telefonica/mistica';

export const FooterText = styled(Text1, {
  lineHeight: '20px!important',
  fontSize: '14px',
  textAlign: 'left!important',
});

export const FooterWrapper = styled('div', {
  padding: '16px 32px 68px',
  background: '#F7F7F7',
  textAlign: 'left',

  '@media(min-width: 992px)': {
    padding: '32px 64px 120px',
  },
  '@media(min-width: 1316px)': {
    padding: '32px calc((100% - 1224px) / 2) 120px',
  },
});
