import { Box, skinVars, Stack, TextLink, useScreenSize } from '@telefonica/mistica';

import { privacyPolicyUrl } from '../../util/constants';

import { FooterText, FooterWrapper } from './footer.styles';
import { useFooter } from './useFooter';

type FooterProps = {
  path: string;
};

export const Footer = ({ path }: FooterProps) => {
  const { shouldDisplayFooter } = useFooter();
  const { isDesktopOrBigger } = useScreenSize();

  if (!shouldDisplayFooter(path)) return null;
  return (
    <FooterWrapper>
      {isDesktopOrBigger ? (
        <Box paddingBottom={24}>
          <Stack space={24}>
            <TextLink style={{ color: skinVars.colors.textPrimary }} to="/regulamento">
              Regulamentos
            </TextLink>
            <TextLink style={{ color: skinVars.colors.textPrimary }} newTab href={privacyPolicyUrl}>
              Política de privacidade
            </TextLink>
            {/* <TextLink style={{ color: skinVars.colors.textPrimary }} newTab href={cookiesPolicyUrl}>
              Política de cookies
            </TextLink> */}
          </Stack>
        </Box>
      ) : null}
      <FooterText regular color={skinVars.colors.textSecondary}>
        Participação válida de 01.11.2023 a 30.11.2023. Consulte{' '}
        <TextLink
          to="/regulamento"
          style={{
            verticalAlign: 'baseline',
          }}
        >
          regulamentos
        </TextLink>{' '}
        completos, com condições de participação e respectivas premiações ofertadas, no site
        www.vivorecargapremiada.com.br
        <br></br>
        <br></br>
        1. Todos os prêmios ofertados nos sorteios serão entregues em cartão de crédito pré-pago,
        sem direito a saque. A imagem dos produtos que aparecem dentro das bolhas são mera sugestão
        de utilização da premiação ofertada.
        <br></br>
        <br></br>
        2. A promoção sob a modalidade vale-brinde poderá ser encerrada antecipadamente caso todos
        os brindes previstos no regulamento sejam distribuídos antes da data prevista para o seu
        encerramento. Nesta modalidade serão ofertados como brindes pacotes de dados de diversas
        configurações conforme especificados em regulamento.
        <br></br>
        <br></br>
        Certificados de autorização SRE/ME N.° 04.030442/2023 e N. ° 05.030431/2023
        <br></br>
        <br></br>
        Imagens meramente ilustrativas.
        <br></br>
        <br></br>A Vivo não solicita que você faça depósitos/Pix para receber recompensas.
      </FooterText>
    </FooterWrapper>
  );
};
