import { FaqComponent } from '../components/faq';
import { HowItWorkComponent } from '../components/how-it-work';
import Landing from '../components/landing';
import { OnBoardingComponent } from '../components/onboarding';
import { RegComponent } from '../components/regulamento';
import { Login } from '../components/signin/login';

export const PUBLIC_ROUTES = [
  {
    publicRoute: true,
    path: '/',
    component: Landing,
    title: '',
  },
  {
    publicRoute: true,
    path: '/landing',
    component: Landing,
    title: '',
  },
  {
    publicRoute: true,
    path: '/como-funciona',
    component: HowItWorkComponent,
    title: '',
  },
  {
    publicRoute: true,
    path: '/onboarding',
    component: OnBoardingComponent,
    title: '',
  },
  {
    publicRoute: true,
    path: '/faq',
    component: FaqComponent,
    title: 'FAQ',
  },
  {
    publicRoute: true,
    path: '/regulamento',
    component: RegComponent,
    title: 'Regulamento',
  },
  {
    publicRoute: true,
    path: '/login',
    component: Login,
    title: 'Login',
  },
  // {
  //   path: '/bubble',
  //   component: Bubble,
  // },
];
