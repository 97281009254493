import { Endpoints, getHeadersEvents, operatorId, serviceId } from '../../util/constants';
import {
  COOKIES_KEYS,
  decodeAccessToken,
  generateDeviceId,
  generateSessionId,
  getCookie,
} from '../../util/functions';

import { WebAppEvent } from './type';

export const useEvents = () => {
  //   const [prizes, setPrizes] = useState<any[] | undefined>(undefined);

  const sendEvents = (params: WebAppEvent) => {
    const { msisdn } = decodeAccessToken();
    params.deviceUuid = generateDeviceId() || undefined;
    params.sessionKey = generateSessionId() || undefined;
    params.serviceId = params.serviceId || serviceId || undefined;
    params.partnerId = params.partnerId || undefined;
    params.operatorId = params.operatorId || operatorId || undefined;
    params.useSession = params.useSession || false;
    params.catId = params.catId;
    params.catName = params.catName;
    params.mediaName = params.mediaName;
    params.url = params.url || window.location.href;
    params.redirectUrl = params.redirectUrl || undefined;
    params.subSource = params.subSource || 'WEB';
    params.eventDateClient = params.eventDateClient || Date.now();
    params.referer = params.referer || document.referrer;
    params.sitename = params.sitename || window.location.host;
    params.userAgent = params.userAgent || navigator.userAgent;
    params.utm_source = getCookie(COOKIES_KEYS.UTM_SOURCE) || undefined;
    params.utm_term = getCookie(COOKIES_KEYS.UTM_TERM) || undefined;
    params.utm_campaign = getCookie(COOKIES_KEYS.UTM_CAMPAIGN) || undefined;
    params.utm_medium = getCookie(COOKIES_KEYS.UTM_MEDIUM) || undefined;
    params.utm_content = getCookie(COOKIES_KEYS.UTM_CONTENT) || undefined;
    params.msisdn = msisdn;
    params.eventCategory = params.eventCategory;
    params.platformType = params.platformType;

    fetch(`${Endpoints.events}/${params.eventType}`, {
      method: 'POST',
      headers: getHeadersEvents(),
      body: JSON.stringify(params),
    }).then((response) => response.json());
  };

  return {
    sendEvents,
  };
};

// export const _trafficType = (id = false) => {
//   const userSession = sessionStorage.getItem('u') ? JSON.parse(sessionStorage.getItem('u')) : {};
//   const result: any =
//     userSession && userSession.dt === true
//       ? [1, '3G']
//       : userSession.dt === false
//       ? [2, 'WIFI']
//       : userSession.dt === null
//       ? [3, 'AUTO']
//       : [undefined, undefined];
//   return result[id ? 0 : 1];
// };

// export const _connection = () =>
//   navigator.connection || navigator.mozConnection || navigator.webkitConnection || undefined;
