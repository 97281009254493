import { LoadingBar, Spinner } from '@telefonica/mistica';

type LoadingRecargaPremiadaProps = {
  visible: boolean;
};

export const LoadingRecargaPremiada = ({ visible }: LoadingRecargaPremiadaProps) => (
  <>
    {visible && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 65px)',
        }}
      >
        <LoadingBar visible />
        <Spinner size={60} />
      </div>
    )}
  </>
);
