import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, useScreenSize } from '@telefonica/mistica';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';

import {
  ContainerBgVivo,
  KnowHowItWorks,
  LandingCampaignOverInfo,
  LandingDescription,
  LandingInfoWrapper,
  StartGameButton,
} from './styles';

const Landing = () => {
  const { sendEvents } = useEvents();
  sendEvents({
    eventType: 'LANDING_PAGE_ACCESS',
    triggerType: 'access',
    eventCategory: isWebview ? 'webview' : 'web',
    platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isDesktopOrBigger } = useScreenSize();
  if (isDesktopOrBigger) {
    return <Navigate to={'/como-funciona'} />;
  }
  // const state = { isFirstLogin: true };

  return (
    <ContainerBgVivo>
      <Box padding={0} className="">
        <LandingCampaignOverInfo>
          Promoção encerrada! Confira os ganhadores no site.
        </LandingCampaignOverInfo>
        <LandingInfoWrapper firstLogin={state?.isFirstLogin}>
          <Box className="landing-footer">
            {!state?.isFirstLogin && (
              <>
                <LandingDescription>
                  <Stack space={24}>
                    <div>
                      Na Recarga Premiada, recarregue R$ 20 ou mais pra ganhar prêmios na hora e
                      participar dos sorteios semanais
                    </div>
                    <div>
                      Ao acumular R$ 30 ou mais, você concorre a R$ 100 mil no sorteio especial
                    </div>
                  </Stack>
                </LandingDescription>
              </>
            )}
            <StartGameButton
              onPress={() => {
                sendEvents({
                  eventType: 'BUTTON_CLICK',
                  triggerType: 'ACCESS',
                  subSource: 'landing',
                  subSubSource: 'Conferir Ganhadores',
                  eventCategory: isWebview ? 'webview' : 'web',
                  platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                });
                navigate('/draws#new-winners');
              }}
              firstLogin={state?.isFirstLogin}
            >
              Conferir Ganhadores
            </StartGameButton>
            <KnowHowItWorks
              onPress={() => {
                sendEvents({
                  eventType: 'BUTTON_CLICK',
                  triggerType: 'CLICK',
                  subSource: 'landing',
                  subSubSource: state?.isFirstLogin ? 'Como ganhar' : 'Conhecer promoção',
                  eventCategory: isWebview ? 'webview' : 'web',
                  platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                });
                navigate('/onboarding');
              }}
            >
              {state?.isFirstLogin ? 'Como ganhar' : 'Conhecer promoção'}
            </KnowHowItWorks>
          </Box>
        </LandingInfoWrapper>
      </Box>
    </ContainerBgVivo>
  );
};

export default Landing;
