import { styled } from '@stitches/react';
import { Box, skinVars, Text5 } from '@telefonica/mistica';

export const LoginTitle = styled(Text5, {
  color: '#000',
  textAlign: 'left',
  fontSize: '20px',
  width: '100%',
  display: 'block',
  lineHeight: '28px!important',
});

export const LoginContainer = styled('div', {
  display: 'block',
  textAlign: 'left',
  // height: '100%',
  padding: '24px 16px',
  '@media(min-width: 992px)': {
    // background: `url(/apiCachefly/brasil/vivo/roblox/assets/login-xg-background_v2.1.png)`,
    // backgroundSize: 'cover',
    // background: 'rgb(88,0,131)',
    background:
      'url(/apiCachefly/brasil/vivo/roblox/assets/login-xg-background_v2.1.png), url(/apiCachefly/brasil/vivo/roblox/assets/login-xg-bg_gradient-v2.png)',
    backgroundRepeat: 'no-repeat, repeat-x',
    backgroundPosition: 'bottom right',
    backgroundSize: 'auto 100%',
    height: 'calc(100vh - 80px)',

    'aside > div > div': {
      paddingLeft: 0,
      marginLeft: '-8px',
    },
  },
});

export const LoginForm = styled(Box, {
  '@media(min-width: 992px)': {
    background: 'white',
    borderRadius: '10px',
    border: `solid 2px ${skinVars.colors.borderLow}`,
    padding: '32px 24px 64px',
    top: '140px',
    position: 'relative',
    // marginLeft: '7%',
  },
});

export const LoginPage = styled('div', {
  display: 'block',
  // height: '100%',
});

export const PhoneNumberLabel = styled('span', {
  marginTop: '4px',
  width: '100%',
  display: 'block',
  fontWeight: 400,
  fontSize: '12px',
  color: skinVars.colors.textSecondary,

  padding: '0 12px',
  /*textAlign: 'center',
  '@media(min-width: 992px)': {
    textAlign: 'left',
  },*/

  variants: {
    phoneNumberInvalid: {
      true: {
        color: skinVars.colors.error,
      },
    },
  },
});

export const LoginHeader = styled('header', {
  background: '#661398',
  height: '80px',
  textAlign: 'left',
  padding: '28px 24px',
  width: '100%',
});

export const InputPin = styled('input', {
  width: '48px',
  height: '48px',
  fontSize: '24px',
  borderRadius: '8px',
  textAlign: 'center',
  border: 'solid 1px #DDDDDD',
  padding: 0,
  borderColor: skinVars.colors.border,
  color: skinVars.colors.textPrimary,
  gap: '12px',
  boxShadow: 'none',
  background: skinVars.colors.backgroundAlternative,
  outline: 'none',

  '&:focus': {
    borderColor: skinVars.colors.borderSelected,
  },

  variants: {
    wrongPin: {
      true: {
        borderColor: skinVars.colors.controlError,
      },
    },
  },

  defaultVariants: {
    wrongPin: false,
  },
});

export const InputPinWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'flex-start',
  input: {
    '&:first-of-type': {
      marginRight: '12px',
    },
    '&:nth-of-type(3)': {
      margin: '0 12px',
    },
  },
});

export const WrongPinWarning = styled('span', {
  textAlign: 'left',
  display: 'block',
  fontSize: '12px',
  marginTop: '4px',
  width: '100%',
  color: skinVars.colors.controlError,
});
