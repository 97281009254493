import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonPrimary,
  Image,
  MediaCard,
  skinVars,
  Text4,
  useScreenSize,
} from '@telefonica/mistica';

import { useEvents } from '../../../hooks/webapp-events/useEvents';
import { recargaUrlMobile, recargaUrlWeb } from '../../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../util/functions';
import { infoDrawsHome } from '../useLogic';

import { ContestGrid } from './home.styles';

export const SorteiosComponent = () => {
  const { sendEvents } = useEvents();
  const { listaSorteios } = infoDrawsHome();
  const recargaUrl = isWebview ? recargaUrlMobile : recargaUrlWeb;
  const navigate = useNavigate();

  const { isTabletOrBigger } = useScreenSize();

  const redirectRecarga = () => {
    window.open(recargaUrl, '_blank');
  };
  return (
    <Box className="my-3 left-text">
      {isTabletOrBigger && (
        <Box paddingBottom={16}>
          <Text4 light color={skinVars.colors.textPrimary}>
            Sorteios
          </Text4>
        </Box>
      )}
      <ContestGrid>
        {listaSorteios?.map((sorteio: any) => (
          <div key={sorteio?.id}>
            {!isTabletOrBigger && (
              <Box paddingBottom={16}>
                <Text4 light color={skinVars.colors.textPrimary}>
                  {sorteio.sectionTitle}
                </Text4>
              </Box>
            )}
            <MediaCard
              title={sorteio.title}
              description={sorteio.description}
              media={<Image aspectRatio="16:9" src={sorteio.image} />}
              button={
                <ButtonPrimary
                  onPress={() => {
                    sendEvents({
                      eventType: 'BUTTON_CLICK',
                      triggerType: 'CLICK',
                      subSource: 'home-page',
                      subSubSource: sorteio.btnText,
                      eventCategory: isWebview ? 'webview' : 'web',
                      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                    });
                    sorteio.scope == 'INTERNAL' ? navigate(sorteio.redirect) : redirectRecarga();
                  }}
                >
                  {sorteio.btnText}
                </ButtonPrimary>
              }
            />
          </div>
        ))}
      </ContestGrid>

      {/* </Inline> */}
    </Box>
  );
};
