import { styled } from '@stitches/react';
import { Box, skinVars } from '@telefonica/mistica';

export const PremioImageWrapper = styled(Box, {
  // borderRadius: '10px',
  backgroundColor: skinVars.colors.backgroundBrand,
  // overflow: 'hidden',
  minHeight: '115px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
});
