import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonPrimary,
  FeedbackScreen,
  IconWarningLight,
  skinVars,
  Stack,
  Text1,
  Text2,
  Text4,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../../App.styles';
import { LoadingRecargaPremiada } from '../../loading/loading';
import { PremioImageWrapper } from '../../Premios/components/premios-detalhes/styles';
import { Selector } from '../../Selector/Selector';
import { DrawsContentApi, GetNewWinnersApi } from '../useLogic';

export const NewGanhadores = () => {
  const navigate = useNavigate();

  const { draws: fetchedDraws, isFetchingDrawsList } = DrawsContentApi();
  const { winnersList, isFetchingWinnersList } = GetNewWinnersApi();

  const [draws, setDraws] = useState<string[]>([]);
  const [selectedDraw, setSelectedDraw] = useState<string>('1º Sorteio');

  const [winners, setWinners] = useState<{ [drawName: string]: any }>({});

  useEffect(() => {
    if (fetchedDraws.length && !isFetchingWinnersList) {
      const tempDraws: string[] = [];
      fetchedDraws.forEach((draw) => {
        if (draw.drawGroup === 3 || draw.drawGroup === 4) {
          // @todo handle this in a more graceful way
          if (draw.drawName === 'R$100mil') {
            draw.drawName = 'Sorteio especial';
          }

          if (!draws.includes(draw.drawName)) {
            tempDraws.push(draw.drawName);
          }
          winners[draw.drawName] = winnersList.find(
            // (winner: any) => winner.drawName === draw.drawDescription,
            (winner: any) => winner.drawId === draw.drawId,
          );
        }
      });
      setDraws(tempDraws);
      setWinners(winners);
    }
  }, [fetchedDraws, winnersList, isFetchingWinnersList]);

  if (isFetchingDrawsList || isFetchingWinnersList) {
    return <LoadingRecargaPremiada visible />;
  }

  return (
    <div className="left-text">
      <Box paddingBottom={32}>
        <Stack space={16}>
          <Text4 regular>Ganhadores</Text4>
          <Text2 regular>Confira os ganhadores de cada sorteio</Text2>
        </Stack>
      </Box>
      <>
        <Box>
          <Selector values={draws.sort()} valueSelected={selectedDraw} onChange={setSelectedDraw} />
        </Box>
        {!winners[selectedDraw] ? (
          <FeedbackScreen
            title="Aguarde! Em breve você vai saber quem foi o ganhador desse sorteio"
            children={
              <Stack space={24}>
                <ButtonPrimary onPress={() => navigate('/home')}>Ir para o início</ButtonPrimary>
              </Stack>
            }
            icon={<IconWarningLight size="65px" color={skinVars.colors.backgroundBrand} />}
            animateText
          />
        ) : (
          <Box paddingTop={24}>
            <Stack space={16}>
              <Text4 light>{winners[selectedDraw].drawName}</Text4>
              <FlexBoxLeft className="border-item m-sm-0">
                <PremioImageWrapper css={{ maxWidth: '80px' }}>
                  <img
                    src={winners[selectedDraw].drawImgUrl}
                    className="img-width-wrapper"
                    alt="premio"
                  />
                </PremioImageWrapper>
                <Box className="mx-4 left-text">
                  <Text2 medium color={skinVars.colors.textPrimary}>
                    {winners[selectedDraw].drawName}
                  </Text2>
                  <br />
                  <Text1 medium color={skinVars.colors.textPrimary}>
                    Nome: {winners[selectedDraw].winnerName}
                  </Text1>
                  <br />
                  <Text1 medium color={skinVars.colors.textPrimary}>
                    N. {winners[selectedDraw].winnerMsisdn}
                  </Text1>
                  <br />
                  <Text1 regular color={skinVars.colors.textSecondary}>
                    Número sorteado: {winners[selectedDraw].luckyNum}
                  </Text1>
                </Box>
              </FlexBoxLeft>
            </Stack>
          </Box>
        )}
      </>
    </div>
  );
};
