import { Route, Routes } from 'react-router-dom';

import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';

import { PRIVATE_ROUTES } from './private-routes';
import { PUBLIC_ROUTES } from './public-routes';
import RouteGuard from './route-guard';

const APP_ROUTES = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES];

export const AppRoutes = () => (
  <Routes>
    {/* <Route path={'/'} element={<Navigate to="/landing" replace></Navigate>} /> */}
    {/* <Route path={'/login'} element={<Login></Login>} /> */}
    {APP_ROUTES.map(({ component: Component, path, title, publicRoute }) => (
      <Route
        key={path}
        path={path}
        element={
          <RouteGuard
            path={path}
            publicRoute={publicRoute}
            children={
              <>
                {!publicRoute && <Header title={title}></Header>}
                <Component />
                <Footer path={path} />
              </>
            }
          />
        }
      />
    ))}
  </Routes>
);
