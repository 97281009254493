import { useEffect, useState } from 'react';
import { Box, Image, ResponsiveLayout, skinVars, Text2, Text3, Text4 } from '@telefonica/mistica';

import { FlexBoxLeft, TitleWrapper } from '../../App.styles';
import { useEvents } from '../../hooks/webapp-events/useEvents';
import { getCurrentMonthLong, getMonthLong } from '../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';
import { LoadingRecargaPremiada } from '../loading/loading';
import { Selector } from '../Selector/Selector';

import { useLogicAPI } from './useLogic';

export const HistoricoRecargasComponent = () => {
  const { sendEvents } = useEvents();
  //Events
  useEffect(() => {
    sendEvents({
      eventType: 'PAYMENT_SUCCESS_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  });
  const { topupsList, isFetchitTopupsList } = useLogicAPI();
  const [monthSelected, setMonthSelected] = useState<string>(getCurrentMonthLong());
  const months = Object.keys(topupsList)
    .map((item) => parseInt(item.split('/')[0]))
    .sort((a, b) => a - b)
    .map((item) => getMonthLong(item).toLocaleLowerCase());

  if (isFetchitTopupsList) {
    return <LoadingRecargaPremiada visible />;
  }

  if (!Object.keys(topupsList).length) {
    return (
      <div className="my-4 mx-3">
        <TitleWrapper>
          <Text4 light color={skinVars.colors.textPrimary}>
            Histórico De Recargas
          </Text4>
        </TitleWrapper>
        <Text2 regular>Não há recargas para mostrar</Text2>
      </div>
    );
  }

  return (
    <ResponsiveLayout>
      <div className="my-4 mx-3">
        <TitleWrapper>
          <Text4 light color={skinVars.colors.textPrimary}>
            Histórico De Recargas
          </Text4>
        </TitleWrapper>
        <Box>
          <Selector
            values={months}
            valueSelected={monthSelected}
            onChange={setMonthSelected}
          ></Selector>

          {Object.values(topupsList).map((topupMonth: any) => {
            if (monthSelected.toLowerCase() !== topupMonth?.month.toLowerCase()) {
              return;
            }

            return (
              <Box key={topupMonth?.month} className="my-4 left-text">
                <Text4 light color={skinVars.colors.textPrimary}>
                  {topupMonth?.month} | R$ {topupMonth?.accumulatedTopupValue}
                </Text4>
                <br />

                {topupMonth?.topupList.map((topup: any) => (
                  <FlexBoxLeft
                    key={topup.topupDate}
                    className="left-text"
                    style={{
                      borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                      padding: '16px 0',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box>
                      <Image
                        src={'/apiCachefly/brasil/vivo/roblox/assets/ic-dollar-papper.svg'}
                        height={28}
                      />
                    </Box>
                    <Box paddingLeft={16}>
                      <Text3 medium color={skinVars.colors.textPrimary}>
                        Valor de recarga: R$ {topup?.topupValue}
                      </Text3>
                      <br />
                      <Text2 regular>{topup?.topupDate.split(' ')[0]}</Text2>
                      <br />
                      <Text2 regular color={skinVars.colors.textSecondary}>
                        Jogada no Estoura Bolha: {topup?.tokens}
                      </Text2>
                    </Box>
                  </FlexBoxLeft>
                ))}
              </Box>
            );
          })}
        </Box>
      </div>
    </ResponsiveLayout>
  );
};
