import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  ButtonPrimary,
  FeedbackScreen,
  IconWinnerLight,
  skinVars,
  Stack,
  Text1,
  Text2,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../../App.styles';
import { useEvents } from '../../../hooks/webapp-events/useEvents';
import { getCurrentMonthLong, getMonthLong } from '../../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../util/functions';
import { LoadingRecargaPremiada } from '../../loading/loading';
import { PremioImageWrapper } from '../../Premios/components/premios-detalhes/styles';
import { Selector } from '../../Selector/Selector';
import { Winner } from '../type';
import { GetWinnersApi } from '../useLogic';

export const LegacyGanhadoresComponent = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'WINNERS_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const navigateTo = useNavigate();
  const { winnersList, isFetchitWinnersList } = GetWinnersApi();
  const months: string[] = Object.keys(winnersList)
    .filter((item) => item.toLocaleLowerCase() !== 'sorteio final')
    .map((item) => parseInt(item.split('/')[0]))
    .sort((a, b) => a - b)
    .map((item) => getMonthLong(item).toLocaleLowerCase());
  months.push('sorteio final');
  const [monthSelected, setMonthSelected] = useState<string>(getCurrentMonthLong());

  if (isFetchitWinnersList) {
    return <LoadingRecargaPremiada visible />;
  }

  return (
    <Stack space={0}>
      <div style={{ marginBottom: '32px', textAlign: 'left' }}>
        <Text2 regular color={skinVars.colors.textSecondary}>
          Confira aqui os sorteios anteriores e os ganhadores dos prêmios
        </Text2>
      </div>
      <Box paddingBottom={80}>
        <Box className="">
          <Selector values={months} valueSelected={monthSelected} onChange={setMonthSelected} />
        </Box>
        <Box>
          {Object.entries(winnersList).map(([_key, value]) => {
            const monthString = value.month;
            if (monthSelected.toLowerCase() !== monthString.toLocaleLowerCase()) {
              return null;
            }
            if (!value.winnerInfoTOList.length) {
              return (
                <Stack key={monthString} space={16}>
                  <FeedbackScreen
                    title="Aguarde! Em breve os ganhadores desse sorteio serão publicados aqui"
                    children={
                      <ButtonPrimary onPress={() => navigateTo('/home')}>
                        Ir para o início
                      </ButtonPrimary>
                    }
                    icon={<IconWinnerLight size="65px" color={skinVars.colors.backgroundBrand} />}
                    animateText
                  />
                  <Box paddingBottom={80}></Box>
                </Stack>
              );
            }

            return (
              <>
                {value.winnerInfoTOList.map((winner: Winner, index: number) => (
                  <div key={index} className="left-text my-3">
                    {/* {index === 0 && (
                  <Box>
                    <Text4 light color={skinVars.colors.textPrimary}>
                      {monthString}
                    </Text4>
                    <br />
                  </Box>
                )} */}
                    <FlexBoxLeft
                      className=""
                      css={{
                        borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                        padding: '12px 0px',
                      }}
                    >
                      <PremioImageWrapper css={{ maxWidth: '80px' }}>
                        <img src={winner.drawImgUrl} className="img-width-wrapper" alt="premio" />
                      </PremioImageWrapper>
                      <Box className="mx-4 left-text">
                        <Text2 medium color={skinVars.colors.textPrimary}>
                          {winner.drawName}
                        </Text2>
                        <br />
                        <Text1 medium color={skinVars.colors.textPrimary}>
                          Nome: {winner.winnerName}
                        </Text1>
                        <br />
                        <Text1 medium color={skinVars.colors.textPrimary}>
                          N. {winner.winnerMsisdn}
                        </Text1>
                        <br />
                        <Text1 regular color={skinVars.colors.textSecondary}>
                          Número sorteado: {winner.luckyNum}
                        </Text1>
                        <br />
                        <Text1 regular color={skinVars.colors.textSecondary}>
                          Número serie: {winner.serie}
                        </Text1>
                      </Box>
                    </FlexBoxLeft>
                  </div>
                ))}
                <Stack space={16} className="row left-text">
                  <Text1 regular color={skinVars.colors.textSecondary}>
                    *Imagem de prêmios meramente ilustrativa
                  </Text1>
                </Stack>
              </>
            );
          })}
        </Box>
      </Box>
    </Stack>
  );
};
