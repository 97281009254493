import { useCallback, useEffect, useState } from 'react';

import { Endpoints, getHeaders } from '../../util/constants';

export interface ErrorTo {
  code: number;
  description: string;
  isError: boolean;
}

export interface RedeemResponse {
  availableCredits: number;
  prizeName: string;
  prizeDescription: string;
  message: string;
  cacheFlyUrl: string;
  expirationDate: string;
  prizeInfo: string;
  errorTO: ErrorTo;
}

export const useLogic = () => {
  const [responseRedeem, setresponseRedeem] = useState<RedeemResponse>();
  const [lottieAnimationJson, setLottieAnimationJson] = useState<object>({});

  const redeemApi = useCallback(() => {
    console.log('Chame redeem');
    fetch(Endpoints.postRedeem, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ serviceId: '170922' }),
    })
      .then((response) => response.json())
      .then((json) => setresponseRedeem(json));
  }, []);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    fetch(`lotties/animacao_completa.json`, {
      headers: headers,
      credentials: 'omit',
    })
      .then((response) => response.json())
      .then((responseLottie) => setLottieAnimationJson(responseLottie));
  }, []);

  return {
    redeemApi,
    responseRedeem,
    lottieAnimationJson,
  };
};
