import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconContactBookRegular,
  IconLogoutRegular,
  IconMobileQuestionRegular,
  IconNotesLight,
  IconTicketsRegular,
  MainNavigationBar,
  Text4,
  useWindowWidth,
} from '@telefonica/mistica';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { privacyPolicyUrl } from '../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview, Logout } from '../../util/functions';

type HeaderProps = {
  title: string;
  selectedIndexProp?: number;
};

type MenuSections = {
  icon: JSX.Element;
  title: string;
  titleDesktop?: string;
  displayOnDesktop?: boolean;
  displayOnWebview?: boolean;
  path: string;
  scope: string;
  event: string;
};

const sections: MenuSections[] = [
  {
    icon: <IconTicketsRegular></IconTicketsRegular>,
    title: 'Início',
    path: '/home',
    scope: 'internal',
    event: 'HOME_CLICK',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconTicketsRegular></IconTicketsRegular>,
    title: 'Meus prêmios',
    path: '/premios#meus-premios',
    scope: 'internal',
    event: 'PRIZE_CLICK',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconTicketsRegular></IconTicketsRegular>,
    title: 'Meus números da sorte',
    path: '/tickets',
    scope: 'internal',
    event: 'TICKETS_HISTORY_CLICK',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconTicketsRegular></IconTicketsRegular>,
    title: 'Ganhadores',
    path: '/draws#ganhadores',
    scope: 'internal',
    event: 'WINNERS_CLICK',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconMobileQuestionRegular></IconMobileQuestionRegular>,
    title: 'Como participar',
    path: '/como-funciona',
    scope: 'internal',
    event: 'TUTORIAL_CLICK',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconMobileQuestionRegular></IconMobileQuestionRegular>,
    title: 'FAQ - Perguntas frequentes',
    titleDesktop: 'FAQ',
    path: '/faq',
    scope: 'internal',
    event: 'FAQ_PAGE_ACCESS',
    displayOnDesktop: true,
    displayOnWebview: true,
  },
  {
    icon: <IconNotesLight></IconNotesLight>,
    title: 'Regulamento',
    path: '/regulamento',
    scope: 'internal',
    event: 'HELP_CLICK',
    displayOnDesktop: false,
    displayOnWebview: true,
  },
  {
    icon: <IconContactBookRegular></IconContactBookRegular>,
    title: 'Politica de privacidade',
    path: privacyPolicyUrl,
    scope: 'external',
    event: 'PRIVACY_POLICY_CLICK',
    displayOnDesktop: false,
    displayOnWebview: true,
  },
  // {
  //   icon: <IconContactBookRegular></IconContactBookRegular>,
  //   title: 'Politica de cookies',
  //   path: cookiesPolicyUrl,
  //   scope: 'external',
  //   event: 'PRIVACY_POLICY_CLICK',
  //   displayOnDesktop: false,
  // },
  {
    icon: <IconLogoutRegular></IconLogoutRegular>,
    title: 'Sair',
    path: '/login',
    scope: 'internal',
    event: 'LOGOUT',
    displayOnDesktop: true,
    displayOnWebview: false,
  },
];

export const Header = ({ title, selectedIndexProp = 0 }: HeaderProps) => {
  const navigateTo = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(selectedIndexProp);
  const subMenuNavigate = useCallback((route: string) => navigateTo(route), [navigateTo]);
  const { logoutApp } = Logout();
  const { sendEvents } = useEvents();
  const screenWidth = useWindowWidth();

  // if (isWebview) {
  //   sections.pop();
  // }
  return (
    <MainNavigationBar
      sections={sections
        .map((section) => section)
        .filter(({ displayOnDesktop }) => (screenWidth > 992 ? !!displayOnDesktop : true))
        .filter(({ displayOnWebview }) => (isWebview ? displayOnWebview : true))
        .map(({ title, titleDesktop, path, scope, event }, idx) => ({
          title: screenWidth > 992 && !!titleDesktop ? titleDesktop : title,
          onPress: () => {
            setSelectedIndex(idx);

            sendEvents({
              eventType: event,
              triggerType: 'CLICK',
              eventCategory: isWebview ? 'webview' : 'web',
              platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
            });

            if (scope === 'external') {
              window.open(path, '_blank');
            } else if (title === 'Sair') {
              logoutApp();
            } else {
              subMenuNavigate(path);
            }
          },
        }))}
      selectedIndex={selectedIndex}
      logo={screenWidth < 992 ? <Text4 medium>{title}</Text4> : <div></div>}
      isInverse={true}
    ></MainNavigationBar>
  );
};
