import { useEffect, useState } from 'react';

import { Endpoints, getHeaders } from '../../../../util/constants';

export const useLogic = () => {
  const [prizes, setPrizes] = useState<any[] | []>([]);
  const [isFetchitPrizesList, setFetchitPrizesList] = useState<boolean>(true);

  useEffect(() => {
    fetch(Endpoints.getRedeemHistory, { headers: getHeaders() })
      .then((response) => response.json())
      .then((json) => setPrizes(json.redeemedPrizeTokenList))
      .finally(() => setFetchitPrizesList(false));
  }, []);

  return {
    prizes,
    isFetchitPrizesList,
  };
};
