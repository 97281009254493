import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { Endpoints, getHeadersWithBasic, serviceId } from '../../util/constants';
import { COOKIES_KEYS, getCookie, getUUID, setCookie } from '../../util/functions';

export interface SendPinResponse {
  description: string;
  result: number;
}

export interface ValidatePinCodeResponse extends SendPinResponse {
  access_token: string;
}

type LoginStep = 'pin-step' | 'msisdn-step';

export const useLogin = () => {
  const [sendPinResponse, setSendPinResponse] = useState<SendPinResponse>();
  const [validatePinCodeResponse, setValidatePinCodeResponse] = useState<ValidatePinCodeResponse>();
  const [loginstep, setLoginStep] = useState<LoginStep>('msisdn-step');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [wrongPin, setWrongPin] = useState<boolean>(false);
  const [isFetchingSendPin, setIsFetchingSendPin] = useState<boolean>(false);
  const [isFetchingHomeInfo] = useState<boolean>(false);
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState<boolean>(false);
  const [isFetchingValidatePin, setIsFetchingValidatePin] = useState<boolean>(false);
  const [pinCodeEventListenerAdded, setPinCodeEventListenerAdded] = useState<boolean>(false);
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const { sendEvents } = useEvents();
  const navigate = useNavigate();

  // const { platformType, setPlatformType } = useGetHomeInfo();

  const sendPinTo = useCallback(
    (msisdnLogin: string) => {
      if (msisdnLogin.length < 13) {
        setPhoneNumberInvalid(true);
        return null;
      }
      setIsFetchingSendPin(true);
      fetch(Endpoints.resetPin, {
        method: 'POST',
        headers: getHeadersWithBasic(),
        body: JSON.stringify({
          serviceId,
          msisdn: msisdnLogin,
          transactionDate: Date.now(),
          transactionId: getUUID(),
        }),
      })
        .then((response) => response.json())
        .then((json) => setSendPinResponse(json))
        .finally(() => {
          setIsFetchingSendPin(false);

          setCookie(COOKIES_KEYS.PLATFORM_TYPE, 'WEB');
          sendEvents({
            eventType: 'MSISDN_SUBMITTED',
            triggerType: 'CLICK',
            platformType: 'WEB',
          });
        });
    },
    [sendEvents],
  );

  const validatePinApi = useCallback((msisdn: string, pinSubmitted: string) => {
    setIsFetchingValidatePin(true);
    fetch(Endpoints.validatePin, {
      method: 'POST',
      headers: getHeadersWithBasic(msisdn),
      body: JSON.stringify({
        serviceId,
        msisdn,
        pin: pinSubmitted,
        transactionDate: Date.now(),
        transactionId: getUUID(),
      }),
    })
      .then((response) => response.json())
      .then((json) => setValidatePinCodeResponse(json))
      .finally(() => setIsFetchingValidatePin(false));
  }, []);

  const submitPin = useCallback(() => {
    const inputs = document.querySelectorAll('input');
    let pin = '';
    inputs.forEach((input) => {
      pin = `${pin}${input.value}`;
    });
    setTimeout(() => {
      validatePinApi(phoneNumber, pin);
    }, 1);
  }, [phoneNumber, validatePinApi]);

  const addPinCodeEventListener = useCallback(() => {
    const inputs = document.querySelectorAll('input');
    inputs[0].focus();
    inputs[0].addEventListener('change', (_event) => {
      // Autocomplete PIN then submit it
      if (inputs[0].value.length === 4) {
        const autoCompleteOTP = inputs[0].value.split('', 4);
        return autoCompleteOTP.forEach((code, key) => {
          inputs[key].focus();
          inputs[key].value = code;
          if (key === 3) {
            return submitPin();
          }
        });
      }
    });
    inputs.forEach((input, key) => {
      input.addEventListener('keyup', (event) => {
        // Reverse erasing inputs
        if (event.code.toLowerCase() === 'backspace') {
          if (key) {
            return inputs[key - 1].focus();
          }
        }
        // Check if has value
        if (input.value) {
          // Ignore when value is not a digit
          if (input.value.match(/\D/)) {
            return (input.value = '');
          }
          // Submit PIN if given value is the last one
          if (key === 3) {
            return submitPin();
          }
          // Focus on the next input
          inputs[key + 1].focus();
        }
      });
    });
    setPinCodeEventListenerAdded(true);
  }, [submitPin]);

  useEffect(() => {
    if (sendPinResponse?.description.toLowerCase() === 'success') {
      setLoginStep('pin-step');
    }
    if (loginstep === 'pin-step' && !pinCodeEventListenerAdded) {
      addPinCodeEventListener();
    }
  }, [addPinCodeEventListener, loginstep, sendPinResponse?.description, pinCodeEventListenerAdded]);

  useEffect(() => {
    // PIN Validation
    if (Boolean(validatePinCodeResponse)) {
      if (validatePinCodeResponse?.result === 0) {
        sendEvents({
          eventType: 'LOGIN_SUCCESS',
          triggerType: 'SUCCESS',
          subSource: 'web',
          platformType: 'WEB',
        });
        // setPlatformType('web');
        // Success
        setCookie(COOKIES_KEYS.PLATFORM_TYPE, 'WEB');
        setCookie(COOKIES_KEYS.ACCESS_TOKEN, validatePinCodeResponse.access_token);
        setUserAuthenticated(true);
        return;
      }
      // Error
      // useEvents({ eventType: 'LOGIN_ERROR', triggerType: 'access' });

      setWrongPin(true);
    }
  }, [validatePinCodeResponse]);

  useEffect(() => {
    if (
      Boolean(userAuthenticated) &&
      !isFetchingHomeInfo &&
      Boolean(getCookie(COOKIES_KEYS.ACCESS_TOKEN))
    ) {
      navigate('/draws#new-winners');

      /*setIsFetchingHomeInfo(true);

      fetch(Endpoints.getHomeInfo, { headers: getHeadersHome() })
        .then((response) => response.json())
        .then((json: GetHomeInfoResponse) => {
          // if (parseInt(json?.lastTopupValue) >= 20) {
          if (json?.userAvailableTokens > 0) {
            return navigate('/bubble');
          }
          navigate('/home');
        })
        .catch((error) => {
          console.error('GetGameInfo', error);
          deleteCookie(COOKIES_KEYS.ACCESS_TOKEN);
        })
        .finally(() => setIsFetchingHomeInfo(false));*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthenticated]);

  return {
    wrongPin,
    sendPinTo,
    loginstep,
    phoneNumber,
    setLoginStep,
    validatePinApi,
    setPhoneNumber,
    setPhoneNumberInvalid,
    isFetchingSendPin,
    isFetchingHomeInfo,
    phoneNumberInvalid,
    isFetchingValidatePin,
    validatePinCodeResponse,
  };
};
