import { useEffect, useState } from 'react';
import { Box, Text3, Title2 } from '@telefonica/mistica';

import { useEvents } from '../../../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../../util/functions';
import { LoadingRecargaPremiada } from '../../../loading/loading';
import { Selector } from '../../../Selector/Selector';
import { PREMIOS } from '../premios.data';
import { PrizeDetails } from '../premios-detalhes';

import { PrizeTOList, useLogic } from './useLogic';

const mapPrizesIdsToTitle = new Map<string, string>([
  [PREMIOS.INSTANTANEOS.ID, PREMIOS.INSTANTANEOS.TITLE],
  [PREMIOS.SEMANAIS.ID, PREMIOS.SEMANAIS.TITLE],
  [PREMIOS.PREMIO_ESPECIAL.ID, PREMIOS.PREMIO_ESPECIAL.TITLE],
]);

export const PremiosDisponiveisComponent = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'PRIZE_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const { prizes, isFetchingPrizes } = useLogic();
  const [prizeTypeSelected, setPrizeTypeSelected] = useState<string>(PREMIOS.INSTANTANEOS.TITLE);
  const prizesToSelect = Array.from(mapPrizesIdsToTitle.values());
  const filteredPrizes = prizes.filter(
    (prize) => mapPrizesIdsToTitle.get(String(prize.prizeDefId)) === prizeTypeSelected,
  );

  if (isFetchingPrizes || !Boolean(prizes)) {
    return <LoadingRecargaPremiada visible />;
  }

  return (
    <Box>
      <Box>
        <Selector
          valueSelected={prizeTypeSelected}
          onChange={setPrizeTypeSelected}
          values={prizesToSelect}
        />
      </Box>
      <Box className="left-text" paddingBottom={16} paddingTop={32}>
        <Title2>
          {prizeTypeSelected.toLowerCase() !== 'prêmio especial'
            ? `Prêmios ${prizeTypeSelected}`
            : prizeTypeSelected}
        </Title2>
      </Box>
      <Box className="row">
        {Boolean(filteredPrizes.length) ? (
          filteredPrizes
            .filter((premio: PrizeTOList) => premio.prizeName.toLowerCase() !== 'sem premio')
            .map((premio: PrizeTOList, index: number) => (
              <Box key={`premio-${index}`} className="col-12 col-md-4 col-lg-3 px-sm-0 px-md-2">
                <PrizeDetails prize={premio} />
              </Box>
            ))
        ) : (
          <Box>
            <br />
            <Text3 regular>Não há {prizeTypeSelected}</Text3>
          </Box>
        )}
      </Box>
    </Box>
  );
};
