import { styled } from '@stitches/react';
import { skinVars } from '@telefonica/mistica';

import { CardDrawSubtitle } from '../../App.styles';

export const LuckyNumbersContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'flex-start',
  justifyContent: 'space-between',
  margin: '16px 0px 32px',
  padding: 0,
  minHeight: 0,
  borderRadius: 0,
  textAlign: 'left',

  '@media(min-width: 768px)': {
    margin: '0',
    padding: '16px',
    minHeight: '106px',
    borderRadius: '10px',
    border: 'solid 1.5px #DDDDDD',
  },
});

export const CardSpecialDraw = styled('div', {
  display: 'flex',
  borderRadius: '16px',
  justifyContent: 'space-between',
  height: '175px',
  alignItems: 'center',
  padding: '24px',
  // backgroundColor: skinVars.colors.backgroundBrand,
  backgroundImage: "url( '/apiCachefly/brasil/vivo/roblox/assets/bg-card-draws.png')",
  backgroundColor: skinVars.colors.backgroundBrand,
  backgroundSize: 'cover',
  position: 'relative',

  '@media(min-width: 768px)': {
    backgroundPositionX: 'max(-235px, -20vw)',
    backgroundPositionY: 'max(-265px, -20vw)',
    backgroundRepeat: 'no-repeat',
  },
});

export const CardSpecialDrawDescription = styled('div', {
  flexGrow: 0,

  [`& ${CardDrawSubtitle}`]: {
    maxWidth: '120px',
  },
});
export const CardSpecialDrawImage = styled('div', {
  img: {
    width: '150px',
  },

  '@media(min-width: 768px)': {
    position: 'absolute',
    left: 'calc(50% - 95px)',
    img: {
      width: '190px',
    },
  },
});
