import { useEffect } from 'react';
import {
  Box,
  ButtonPrimary,
  FeedbackScreen,
  IconWarningLight,
  skinVars,
} from '@telefonica/mistica';

import { useEvents } from '../../../../hooks/webapp-events/useEvents';
import { recargaUrlMobile, recargaUrlWeb } from '../../../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../../../util/functions';
import { LoadingRecargaPremiada } from '../../../loading/loading';
import { PrizeDetails } from '../premios-detalhes';

import { useLogic } from './useLogic';

export const PremiosGanhosComponent = () => {
  //Events
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'PRIZE_HISTORY_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const { prizes, isFetchitPrizesList } = useLogic();
  const displayResultEmpty = !prizes.length && !isFetchitPrizesList;

  const recargaUrl = isWebview ? recargaUrlMobile : recargaUrlWeb;
  const redirectRecarga = () => {
    sendEvents({
      eventType: 'USER_REDIRECT',
      triggerType: 'CLICK',
      redirectUrl: recargaUrl,
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
    window.open(recargaUrl, '_blank');
  };

  if (isFetchitPrizesList) {
    return <LoadingRecargaPremiada visible />;
  }

  if (displayResultEmpty) {
    return (
      <Box className="left-text">
        <FeedbackScreen
          title="Você ainda não ganhou nenhum prêmio!"
          description="Que tal recarregar R$ 20 ou mais para começar o jogo? Não fique de fora!"
          children={<ButtonPrimary onPress={redirectRecarga}>Fazer recarga</ButtonPrimary>}
          icon={<IconWarningLight size="65px" color={skinVars.colors.backgroundBrand} />}
          animateText
        />
      </Box>
    );
  }

  let key = 0;
  return (
    <Box className="row">
      {prizes &&
        prizes.map((premio) => {
          key++;
          return (
            <Box key={key} className="col-12 col-md-4 col-lg-3 px-sm-0 px-md-2">
              <PrizeDetails prize={premio}></PrizeDetails>
            </Box>
          );
        })}
    </Box>
  );
};
