/* eslint-disable no-unused-vars */
export const PREMIOS = {
  INSTANTANEOS: {
    ID: '1',
    TITLE: 'Instantâneos',
  },
  MENSAIS: {
    ID: '2',
    TITLE: 'Mensais',
  },
  SEMANAIS: {
    ID: '4',
    TITLE: 'Semanais',
  },
  PREMIO_ESPECIAL: {
    ID: '3',
    TITLE: 'Prêmio especial',
  },
};
