import { useScreenSize } from '@telefonica/mistica';

export const useFooter = () => {
  const { isDesktopOrBigger } = useScreenSize();
  const pagesThatHasFooter = ['home', 'onboarding', 'draws', 'premios', 'como-funciona'];

  const shouldDisplayFooter = (path: string) => {
    if (isDesktopOrBigger && !path.match('login')) {
      return true;
    }
    return pagesThatHasFooter.includes(path.replace('/', ''));
  };
  return {
    pagesThatHasFooter,
    shouldDisplayFooter,
  };
};
