import { useEffect, useState } from 'react';

import { Endpoints, getHeaders } from '../../util/constants';

// AJUSTAR TYPES MEDIANTE A RESPOSTA (NÃO HÁ API PARA ISTO)
export const useLogicAPI = () => {
  const [topupsList, setTopupsList] = useState<object>({});
  const [isFetchitTopupsList, setIsFetchitTopupsList] = useState<boolean>(true);

  useEffect(() => {
    fetch(Endpoints.topupHistory, { headers: getHeaders() })
      .then((response) => response.json())
      .then((json) => setTopupsList(json.topupHistory))
      .finally(() => setIsFetchitTopupsList(false));
  }, []);

  return {
    topupsList,
    isFetchitTopupsList,
  };
};
