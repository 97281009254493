import React, { useEffect, useState } from 'react';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import {
  Box,
  ButtonLayout,
  ButtonPrimary,
  IconTimeRegular,
  Image,
  LoadingBar,
  skinVars,
  Stack,
  Text3,
  Text4,
} from '@telefonica/mistica';

import { BackgroundBubble, BubbleContainer, FlexBoxCenter, FlexBoxLeft } from '../../App.styles';
import { useEvents } from '../../hooks/webapp-events/useEvents';
import { useGetHomeInfo } from '../../services/useGetHomeInfo';
import { recargaUrlMobile, recargaUrlWeb } from '../../util/constants';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';

import { useDialogSuccess } from './popupsuccess';
import { useLogic } from './useLogic';

export type BubbleGameSteps = 'step1' | 'step2' | 'step3' | 'notokens';

export const Bubble = () => {
  const { sendEvents } = useEvents();

  const [gamestep, setGameStep] = useState<BubbleGameSteps>('step1');
  const { homeInfo, isFetchingHomeInfo } = useGetHomeInfo();
  const { redeemApi, responseRedeem, lottieAnimationJson } = useLogic();
  const recargaUrl = isWebview ? recargaUrlMobile : recargaUrlWeb;
  const [remainingPlays, setRemainingPlays] = useState<number>(0);
  const [bubbleAnimationCompleted, setBubbleAnimationCompleted] = useState<boolean>(false);
  const { dialogSuccess } = useDialogSuccess();

  useEffect(() => {
    if (!isFetchingHomeInfo) {
      if (homeInfo.userAvailableTokens <= 0) {
        setGameStep('notokens');
      }
      setRemainingPlays(homeInfo.userAvailableTokens);
      sendEvents({
        eventType: 'GAME_PAGE_ACCESS',
        triggerType: 'ACCESS',
        eventCategory: isWebview ? 'webview' : 'web',
        platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
      });
    }
  }, [homeInfo]);

  useEffect(() => {
    if (
      responseRedeem &&
      bubbleAnimationCompleted &&
      responseRedeem.availableCredits !== remainingPlays
    ) {
      setRemainingPlays(responseRedeem.availableCredits);
      dialogSuccess({
        responseRedeem,
        setGameStep,
      });
      setBubbleAnimationCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bubbleAnimationCompleted, responseRedeem]);

  const clickFirstStep = () => {
    sendEvents({
      eventType: 'GAME_PLAYED',
      triggerType: 'CLICK',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
    redeemApi();
    setGameStep('step3');
  };

  const redirectRecarga = () => {
    sendEvents({
      eventType: 'USER_REDIRECT',
      triggerType: 'CLICK',
      redirectUrl: recargaUrl,
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
    window.open(recargaUrl, '_blank');
  };

  return (
    // <ResponsiveLayout className="background-bubble">
    <BackgroundBubble noTokens={remainingPlays == 0}>
      <LoadingBar visible={isFetchingHomeInfo} />
      <BubbleContainer>
        <Box paddingX={8} paddingY={8}>
          {!isFetchingHomeInfo && remainingPlays == 0 ? (
            <Stack space={0}>
              <div className="text-center mb-4">
                <img
                  className="img-inst-premio"
                  src="/apiCachefly/brasil/vivo/roblox/assets/premios_instantaneos.svg"
                ></img>
              </div>
              <FlexBoxLeft className="left-text mt-4">
                <Box className="mx-3">
                  <Image
                    width={32}
                    src={'/apiCachefly/brasil/vivo/roblox/assets/IconMobileDolar.svg'}
                  ></Image>
                </Box>

                <Text3 regular color={skinVars.colors.background}>
                  Você não tem jogadas disponíveis. Recarregue R$ 20 ou mais para ganhar prêmios
                </Text3>
              </FlexBoxLeft>
              <FlexBoxLeft className="left-text my-4">
                <Box className="mx-3">
                  <IconTimeRegular size={32} color={skinVars.colors.background}></IconTimeRegular>
                </Box>

                <Text3 regular color={skinVars.colors.background}>
                  Se já fez a recarga, mas suas jogadas não estão disponíveis, não se preocupe! Em
                  até 3 dias vão aparecer aqui.
                </Text3>
              </FlexBoxLeft>
              <Stack className="mt-5" space={0}>
                <div className="bubble-no-credits-button">
                  <ButtonLayout align={'full-width'}>
                    <ButtonPrimary onPress={redirectRecarga}>Fazer recarga</ButtonPrimary>
                  </ButtonLayout>
                </div>
              </Stack>
            </Stack>
          ) : null}
          {gamestep === 'step1' && remainingPlays > 0 ? (
            <Stack space={'around'}>
              <Box>
                <Text4 medium color={skinVars.colors.textPrimaryInverse}>
                  Escolha uma bolha premiada e descubra o seu prêmio na hora!
                </Text4>
              </Box>
              <div className="row py-3 clickBubbles" onClick={() => clickFirstStep()}>
                <div className="col-6 p-0">
                  <img
                    className="bubble"
                    style={{
                      bottom: '-20px',
                    }}
                    src="/apiCachefly/brasil/vivo/roblox/assets/bolha_presente.svg"
                  ></img>
                </div>
                <div className="col-6 p-0">
                  <img
                    className="bubble"
                    style={{
                      top: '-20px',
                    }}
                    src="/apiCachefly/brasil/vivo/roblox/assets/bolha_presente_2.svg"
                  ></img>
                </div>
                <div className="col-6 p-0">
                  <img
                    className="bubble"
                    style={{
                      bottom: '-20px',
                    }}
                    src="/apiCachefly/brasil/vivo/roblox/assets/bolha_presente_3.svg"
                  ></img>
                </div>
                <div className="col-6 p-0">
                  <img
                    className="bubble"
                    style={{
                      top: '-20px',
                      left: '-10px',
                    }}
                    src="/apiCachefly/brasil/vivo/roblox/assets/bolha_presente_4.svg"
                  ></img>
                </div>
              </div>
              <Box paddingY={8} paddingX={16} className="row-available-tokens my-4">
                <FlexBoxCenter>
                  <div className="py-2 px-3 ticketAvailableBox mx-3">
                    <Text4 medium color={skinVars.colors.textPrimaryInverse}>
                      {remainingPlays}
                    </Text4>
                  </div>
                  <Text4 medium color={skinVars.colors.textPrimaryInverse}>
                    {`Jogada${remainingPlays > 1 ? 's' : ''}`}
                  </Text4>
                </FlexBoxCenter>
              </Box>
            </Stack>
          ) : null}
          {gamestep === 'step2' ? (
            <Stack space={'around'}>
              <div className="d-flex justify-content-center py-3">
                {/* <Image width={320} src={mainBubble}></Image> */}
                <img src="/apiCachefly/brasil/vivo/roblox/assets/bolha_presente_grande.svg"></img>
              </div>
            </Stack>
          ) : null}
          {gamestep == 'step3' ? (
            <Stack space={'around'}>
              <div className="d-flex justify-content-center py-3">
                <Player
                  loop={false}
                  src={lottieAnimationJson}
                  style={{ height: '450px', width: '450px' }}
                  onEvent={(playerEvent: PlayerEvent) =>
                    playerEvent === PlayerEvent.Complete ? setBubbleAnimationCompleted(true) : null
                  }
                  autoplay
                ></Player>
              </div>
            </Stack>
          ) : null}
        </Box>
      </BubbleContainer>
      {/* </ResponsiveLayout> */}
    </BackgroundBubble>
  );
};

export default Bubble;
