import { useEffect, useState } from 'react';

import { Endpoints, getHeaders } from '../../util/constants';

export interface PrizeTo {
  prizeName: string;
  prizeDefId: string;
  cacheFlyUrl: string;
  prizeDescription: string;
  prizeInfo: string;
  quantity: string;
}
export interface DrawToList {
  drawId: number;
  drawGroup: number;
  isActive: boolean;
  accumulatedTickets: number;
  drawName: string;
  drawDescription: string;
  drawDate: number;
  drawImgUrl: string;
  drawStartDate: number;
  drawEndDate: number;
  status: string;
}

export interface ErrorTo {
  code: number;
  description: string;
  isError: boolean;
}

export interface DrawList {
  drawTOList: DrawToList[];
  errorTO: ErrorTo;
}

export const DrawsContentApi = () => {
  const [draws, setDraws] = useState<DrawToList[]>([]);
  const [isFetchingDrawsList, setIsFetchingDrawsList] = useState<boolean>(true);

  useEffect(() => {
    fetch(Endpoints.getDrawList, { headers: getHeaders() })
      .then((response) => response.json())
      .then((json) => setDraws(json.drawTOList))
      .finally(() => setIsFetchingDrawsList(false));
  }, []);

  return { draws, isFetchingDrawsList };
};

export const GetWinnersApi = () => {
  const [winnersList, setWinnersList] = useState<object>({});
  const [isFetchitWinnersList, setIsFetchitTopupsList] = useState<boolean>(true);

  useEffect(() => {
    fetch(Endpoints.getWinnerInfo, { headers: getHeaders() })
      .then((response) => response.json())
      // .then(() => setWinnersList({}))
      .then((json) => setWinnersList(json.winnersHistoryTO))
      .finally(() => setIsFetchitTopupsList(false));
  }, []);

  return {
    winnersList,
    isFetchitWinnersList,
  };
};

export const GetNewWinnersApi = () => {
  const [winnersList, setWinnersList] = useState<any[]>([]);
  const [isFetchingWinnersList, setIsFetchingWinnersList] = useState<boolean>(true);

  useEffect(() => {
    fetch(Endpoints.getWinnerInfoV2, { headers: getHeaders() })
      .then((response) => response.json())
      // .then(() => setWinnersList({}))
      .then((json) => setWinnersList(json.WinnerInfoTOV2))
      .finally(() => setIsFetchingWinnersList(false));
  }, []);

  return {
    winnersList,
    isFetchingWinnersList,
  };
};
