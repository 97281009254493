import { styled } from '@stitches/react';
import { Box, skinVars } from '@telefonica/mistica';

export const RobloxHeader = styled('header', {
  background: '#661398',
  height: '170px',
  paddingTop: '10px',
  width: '100%',
});

export const FlexBoxLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'flex-start',
  justifyContent: 'left',
});

export const FlexBoxCenter = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
});
export const FlexBoxAround = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
});
export const CardDraw = styled('div', {
  display: 'flex',
  borderRadius: '16px',
  justifyContent: 'space-between',
  height: '175px',
  alignItems: 'center',
  padding: '16px',
  // backgroundColor: skinVars.colors.backgroundBrand,
  backgroundImage: "url( '/apiCachefly/brasil/vivo/roblox/assets/bg-card-draws.png')",
  backgroundColor: skinVars.colors.backgroundBrand,
  backgroundSize: 'cover',
});

export const CardDrawTitle = styled('div', {
  color: skinVars.colors.textPrimaryInverse,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '8px',
});
export const CardDrawSubtitle = styled('div', {
  color: skinVars.colors.textPrimaryInverse,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});

export const BubbleContainer = styled('div', {
  display: 'block',
  textAlign: 'center',
  margin: 'auto',
  padding: '30px 15px',
  maxWidth: '600px',
});
export const DefaultCard = styled('div', {
  // display: 'flex',
  // textAlign: 'center',
  height: '100%',
  borderRadius: '10px',
  background: 'linear-gradient(to bottom, #fdfdfd, #eaeaea, #d7d7d7, #c5c5c5, #b3b3b3)',
});
export const DefaultCardHeader = styled('div', {
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px 15px',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  background: 'linear-gradient(to right, #d986f0, #ff74c3, #ff6f91, #ff7a61, #ec8e38);',
  color: 'white',
  alignItems: 'center',
});
export const DefaultCardBody = styled('div', {
  // display: 'flex',
  textAlign: 'center',
  // justifyContent: 'space-between',
  width: '100%',
  padding: '15px',
  borderBottomRightRadius: '10px',
  borderBottomLeftRadius: '10px',
  alignItems: 'center',
});

export const AppContainer = styled('div', {
  // height: '100vh',
  // alignContent: 'center',
  background: skinVars.colors.background,
  textAlign: 'center',
});

export const TitleWrapper = styled(Box, {
  textAlign: 'left',
  marginBottom: '16px',
});

export const BigTicketWrapper = styled('div', {
  height: '59px',
  backgroundImage: "url('/apiCachefly/brasil/vivo/roblox/assets/numero-da-sorte-v2.svg')",
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  maxWidth: '324px',
  margin: 'auto',
  marginBottom: '15px',
  padding: '0 36px 0 26px',

  '@media (min-width: 992px)': {
    height: '82px',
    backgroundImage: "url('/apiCachefly/brasil/vivo/roblox/assets/numero-da-sorte-lg.svg')",
    backgroundPosition: 'center',
    maxWidth: 'calc(50% - 12px)',
    margin: '0',
    marginTop: '0 !important',
  },
});

export const BackgroundBubble = styled('div', {
  backgroundImage: "url('/apiCachefly/brasil/vivo/roblox/assets/background_bubble.png')",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: 'calc(100vh - 56px)',

  variants: {
    noTokens: {
      true: {
        backgroundImage:
          "url('/apiCachefly/brasil/vivo/roblox/assets/background_bubble_no_tokens.png')",
      },
    },
  },
});
