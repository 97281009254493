import { useEffect, useState } from 'react';
import { Box, LoadingBar, ResponsiveLayout, Stack, Tabs } from '@telefonica/mistica';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';
import {
  GanhadoresSelection,
  LEGACY_WINNERS,
  NEW_WINNERS,
} from '../draws-content/_components/ganhadores-selection';
import { GrandePremio } from '../draws-content/_components/grande-premio';
import { SemanalComponent } from '../draws-content/_components/semanal';
import { DrawsContentApi } from '../draws-content/useLogic';
import { DesktopHomeBanner } from '../home/components/home.styles';

const Draws = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'DRAW_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const { draws } = DrawsContentApi();
  const fragment: string = window.location.hash.toLocaleLowerCase();
  const initialTabState =
    fragment === '#premio-especial'
      ? 1
      : fragment === '#ganhadores' || fragment === LEGACY_WINNERS || fragment === NEW_WINNERS
      ? 2
      : 0;
  const [selectedDrawTabIndex, setSelectedDrawTabIndex] = useState<number>(initialTabState);
  const availableTabs = [
    { key: 0, text: 'Semanal' },
    { key: 1, text: 'Especial' },
    { key: 2, text: 'Ganhadores' },
  ];

  const setTab = (index: number) => {
    setSelectedDrawTabIndex(index);
    window.location.replace('#' + availableTabs.find((tab) => tab.key === index)?.text || '');
  };

  return (
    <Box>
      <DesktopHomeBanner></DesktopHomeBanner>
      <ResponsiveLayout>
        <LoadingBar visible={!Boolean(draws)} />
        <Stack space={8}>
          <div className="tabs-sorteios">
            <Tabs
              selectedIndex={selectedDrawTabIndex}
              tabs={availableTabs}
              onChange={setTab}
            ></Tabs>
          </div>
          <Box paddingY={24} className="tab-content">
            {selectedDrawTabIndex === 0 ? (
              <SemanalComponent draws={draws}></SemanalComponent>
            ) : null}
            {selectedDrawTabIndex === 1 ? <GrandePremio draws={draws}></GrandePremio> : null}
            {selectedDrawTabIndex === 2 ? <GanhadoresSelection></GanhadoresSelection> : null}
          </Box>
        </Stack>
      </ResponsiveLayout>
    </Box>
  );
};

export default Draws;
