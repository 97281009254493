import { ChangeEvent, useEffect } from 'react';
import {
  ButtonFixedFooterLayout,
  ButtonLayout,
  ButtonLink,
  ButtonPrimary,
  LoadingBar,
  PhoneNumberField,
  ResponsiveLayout,
  skinVars,
  Stack,
  Text3,
  Text4,
} from '@telefonica/mistica';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';

import {
  InputPin,
  InputPinWrapper,
  LoginContainer,
  LoginForm,
  LoginHeader,
  LoginPage,
  LoginTitle,
  PhoneNumberLabel,
  WrongPinWarning,
} from './styles';
import { useLogin } from './useLogin';

export const Login = () => {
  const { sendEvents } = useEvents();
  useEffect(() => {
    sendEvents({
      eventType: 'LOGIN_PAGE_ACCESS',
      triggerType: 'access',
      eventCategory: isWebview ? 'webview' : 'web',
      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
    });
  }, []);

  const {
    sendPinTo,
    loginstep,
    phoneNumber,
    isFetchingValidatePin,
    setPhoneNumber,
    setPhoneNumberInvalid,
    wrongPin,
    isFetchingSendPin,
    isFetchingHomeInfo,
    phoneNumberInvalid,
  } = useLogin();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const msisdn = event.target.value.replace(/\D/g, '');
    setPhoneNumberInvalid(false);
    setPhoneNumber(msisdn);
  };

  const sendPinCode = () => {
    sendPinTo(`55${phoneNumber}`);
  };

  return (
    <LoginPage>
      <LoadingBar visible={isFetchingSendPin || isFetchingValidatePin || isFetchingHomeInfo} />
      <LoginHeader>
        <Text4 medium color="white">
          Entrar
        </Text4>
      </LoginHeader>
      <ResponsiveLayout fullWidth>
        <LoginContainer>
          <LoginForm className="col-lg-5 offset-lg-1">
            {loginstep === 'msisdn-step' && (
              <Stack space={16}>
                <LoginTitle>Insira seu número</LoginTitle>
                <Text3 regular color="#666">
                  Para acessar a página da Recarga Premiada, insira o número do seu Vivo Pré com DDD
                  e 9 dígitos no campo &quot;Celular&quot;
                </Text3>
                <Text3 regular color="#666">
                  Você vai receber um código por SMS nesse mesmo número
                </Text3>
                <div>
                  <PhoneNumberField
                    label={'Celular'}
                    name={'msisdn'}
                    onChange={handleChange}
                    prefix="+55"
                    maxLength={13}
                    error={phoneNumberInvalid}
                  ></PhoneNumberField>
                  {phoneNumberInvalid ? (
                    <PhoneNumberLabel phoneNumberInvalid={phoneNumberInvalid}>
                      Tem algo errado com seu número. Dá uma conferida
                    </PhoneNumberLabel>
                  ) : (
                    <PhoneNumberLabel color="#86888C">
                      Digite seu DDD seguido do seu número de celular Vivo
                    </PhoneNumberLabel>
                  )}
                </div>
                <ButtonFixedFooterLayout
                  isFooterVisible
                  desktopButtonAlign="left"
                  children={null}
                  button={
                    <ButtonLayout align="full-width" withMargins={false}>
                      <ButtonPrimary
                        disabled={!phoneNumber || isFetchingSendPin || phoneNumberInvalid}
                        onPress={sendPinCode}
                      >
                        Continuar
                      </ButtonPrimary>
                    </ButtonLayout>
                  }
                ></ButtonFixedFooterLayout>
              </Stack>
            )}
            {loginstep === 'pin-step' && (
              <Stack space={16}>
                <LoginTitle>Acesse com seu código</LoginTitle>
                <Text3 regular color="#666">
                  Coloque aqui o código de 4 dígitos que enviamos pra você por SMS
                </Text3>
                <div>
                  <InputPinWrapper>
                    <InputPin
                      type="text"
                      inputMode="numeric"
                      autoComplete="one-time-code"
                      pattern="\d"
                      maxLength={4}
                      wrongPin={wrongPin}
                    />
                    <InputPin
                      type="text"
                      inputMode="numeric"
                      pattern="\d"
                      maxLength={1}
                      wrongPin={wrongPin}
                    />
                    <InputPin
                      type="text"
                      inputMode="numeric"
                      pattern="\d"
                      maxLength={1}
                      wrongPin={wrongPin}
                    />
                    <InputPin
                      type="text"
                      inputMode="numeric"
                      pattern="\d"
                      maxLength={1}
                      wrongPin={wrongPin}
                    />
                  </InputPinWrapper>
                  <WrongPinWarning hidden={!wrongPin}>
                    Tem algo errado com seu número. Dá uma conferida
                  </WrongPinWarning>
                </div>
                <ButtonLink
                  onPress={() => {
                    sendPinCode();
                    sendEvents({
                      eventType: 'PIN_RESEND_REQUEST',
                      triggerType: 'CLICK',
                      platformType: 'WEB',
                    });
                  }}
                >
                  <Text3 color={skinVars.colors.textLink} medium>
                    Receber um novo código
                  </Text3>
                </ButtonLink>
              </Stack>
            )}
          </LoginForm>
        </LoginContainer>
      </ResponsiveLayout>
    </LoginPage>
  );
};
