import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  IconChevronRightRegular,
  ResponsiveLayout,
  skinVars,
  Text2,
  Text3,
  Text4,
  TextLink,
} from '@telefonica/mistica';

import { FlexBoxLeft } from '../../App.styles';
import { useEvents } from '../../hooks/webapp-events/useEvents';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';
import { Header } from '../Header/Header';

import { useFaqsList } from './useLogic';

export const FaqComponent = () => {
  const { sendEvents } = useEvents();
  const navigateTo = useNavigate();
  const [faqSelected, setfaqSelected] = useState<any>(null);

  const { faqs } = useFaqsList();
  let key = 0;
  return (
    <>
      <Header title="FAQ" selectedIndexProp={5}></Header>
      <ResponsiveLayout>
        {faqSelected == null ? (
          <div className="my-3 mx-3">
            <Box className="left-text">
              <TextLink
                className="my-3"
                onPress={() => {
                  navigateTo('/home');
                }}
              >
                Voltar para home
              </TextLink>
              <br></br>
              <Text4 regular> FAQ - Perguntas Frequentes</Text4>
            </Box>
            <Box className="my-4">
              {faqs?.map((faq: any) => {
                key++;
                return (
                  <FlexBoxLeft
                    key={key}
                    className="left-text justify-content-between"
                    css={{
                      borderBottom: `1px solid ${skinVars.colors.backgroundSkeleton}`,
                      padding: '12px 30px',
                    }}
                    onClick={() => {
                      sendEvents({
                        eventType: 'FAQ_CLICK',
                        triggerType: 'CLICK',
                        subSource: faq?.question,
                        eventCategory: isWebview ? 'webview' : 'web',
                        platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                      });
                      setfaqSelected(faq);
                    }}
                  >
                    <Text3 regular>{faq?.question}</Text3>
                    <Box>
                      <IconChevronRightRegular></IconChevronRightRegular>
                    </Box>
                  </FlexBoxLeft>
                );
              })}
            </Box>
          </div>
        ) : (
          <div className="my-3 mx-3">
            <Box className="left-text">
              <TextLink
                className="my-3"
                onPress={() => {
                  setfaqSelected(null);
                }}
              >
                Voltar para o FAQ
              </TextLink>
              <br></br>
              <Text4 regular>{faqSelected?.question}</Text4>
              <br></br>
              <br></br>
              <Text2 regular>{faqSelected?.answer}</Text2>
            </Box>
            {!!faqSelected && faqSelected.extraTable ? (
              <table className="mt-3">
                <thead>
                  <tr>
                    {faqSelected.extraTable.headers.map((header: string, i: number) => (
                      <td key={i}>{header}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {faqSelected.extraTable.data.map((row: string[], i: number) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td key={i + '-' + j}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
            <Box></Box>
          </div>
        )}
      </ResponsiveLayout>
    </>
  );
};
