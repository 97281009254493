import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconChevronRightRegular, Inline, skinVars, Stack, Text2 } from '@telefonica/mistica';

import { LuckyNumbersContainer } from '../styles';

import { LegacyGanhadoresComponent } from './legacy-ganhadores';
import { NewGanhadores } from './new-ganhadores';

export const LEGACY_WINNERS = '#legacy-winners';
export const NEW_WINNERS = '#new-winners';

export const GanhadoresSelection = () => {
  const [selectedView, setSelectedView] = useState<
    typeof LEGACY_WINNERS | typeof NEW_WINNERS | null
  >(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash !== LEGACY_WINNERS && location.hash !== NEW_WINNERS) {
      setSelectedView(null);
    } else {
      setSelectedView(location.hash);
    }
  }, [location]);

  const goToView = (viewHash: typeof LEGACY_WINNERS | typeof NEW_WINNERS) => {
    navigate(viewHash);
    setSelectedView(viewHash);
  };

  if (!selectedView) {
    return (
      <Stack space={24}>
        <LuckyNumbersContainer onClick={() => goToView(LEGACY_WINNERS)}>
          <Inline space={12} alignItems="center">
            <img
              className="mr-3"
              src="/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-bg-purple.png"
            />
            <>
              <Text2 medium color={skinVars.colors.textPrimary}>
                Ganhadores Recarga Premiada
              </Text2>
            </>
          </Inline>
          <Box>
            <IconChevronRightRegular />
          </Box>
        </LuckyNumbersContainer>

        <LuckyNumbersContainer onClick={() => goToView(NEW_WINNERS)}>
          <Inline space={12} alignItems="center">
            <img
              className="mr-3"
              src="/apiCachefly/brasil/vivo/roblox/assets/ic-ticket-bg-purple.png"
            />
            <>
              <Text2 medium color={skinVars.colors.textPrimary}>
                Ganhadores Recarga Premiada - Última chance
              </Text2>
            </>
          </Inline>
          <Box>
            <IconChevronRightRegular />
          </Box>
        </LuckyNumbersContainer>
      </Stack>
    );
  }

  return selectedView === LEGACY_WINNERS ? <LegacyGanhadoresComponent /> : <NewGanhadores />;
};
