import { useEffect, useState } from 'react';

import { Endpoints, getHeaders } from './../../../../util/constants';

export interface PrizeTOList {
  prizeName: string;
  prizeDefId: string;
  cacheFlyUrl: string;
  prizeDescription: string;
  prizeInfo: string;
  prizeMessage: string;
}

export interface ErrorTO {
  code: number;
  description: string;
  isError: boolean;
}

export interface ListPrizesResponse {
  prizeTOList: PrizeTOList[];
  errorTO: ErrorTO;
}

export const useLogic = () => {
  const [prizes, setPrizes] = useState<PrizeTOList[]>([]);
  const [isFetchingPrizes, setIsFetchingPrizes] = useState<boolean>(true);
  useEffect(() => {
    fetch(Endpoints.getPrizeList, { headers: getHeaders() })
      .then((response) => response.json())
      .then((json: ListPrizesResponse) => setPrizes(json.prizeTOList))
      .finally(() => setIsFetchingPrizes(false));
  }, []);

  return {
    prizes,
    isFetchingPrizes,
  };
};
